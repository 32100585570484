import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import DElement from "./DElement";

function Dashboard() {
  const [TVL, setTVL] = useState(0);
  const { dashboard } = useSelector(selectProposal);

  const {
    MC,
    CURRENT_PRICE,
    totalRVLTstaked,
    cultBurned,
    totalTreasuryBalance,
    rvltEthPrice,
    totalInvestmentFund,
  } = dashboard.cData;

  useEffect(() => {
    const data = async () => {
      const tvLocked = CURRENT_PRICE ? CURRENT_PRICE * totalRVLTstaked : "";

      setTVL(tvLocked);
    };
    data();
  }, [CURRENT_PRICE, totalRVLTstaked]);

  return (
    <>
      <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
        <DElement name="RVLT Price" value={CURRENT_PRICE} symbol="$" />
        <DElement name="Marketcap" value={MC?.toLocaleString()} symbol="$" />
        <DElement
          name="Total Value Locked"
          value={TVL > 1 ? parseFloat(TVL.toFixed(3)).toLocaleString() : 0}
          symbol="$"
        />
      </div>
      <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
        <DElement
          name="Treasury Balance"
          value={
            totalTreasuryBalance &&
            parseFloat(rvltEthPrice) &&
            parseFloat(
              (
                parseFloat(rvltEthPrice)?.toFixed(12) * totalTreasuryBalance
              )?.toFixed(3)
            ).toLocaleString()
          }
          symbol=" ETH"
        />
        <DElement
          name="Revolutionary Rewards"
          value={
            parseFloat(totalInvestmentFund) &&
            parseFloat(totalInvestmentFund).toLocaleString()
          }
          symbol=" ETH"
        />
        <DElement
          name="RVLT Burned"
          value={parseInt(cultBurned) && parseInt(cultBurned).toLocaleString()}
          symbol=""
        />
      </div>
    </>
  );
}

export default Dashboard;
