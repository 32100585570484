import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isCultMandator } from "../../Common/rvltFunctions";
import { selectProposal } from "../../features/counter/proposalSlice";
import BackButton from "../BackButton";

function ProposalOptions() {
  const { isMetamask } = useSelector(selectProposal);
  const [isCMandator, setIsCMandator] = useState(false);

  useEffect(() => {
    const v = async () => {
      if (!isMetamask) {
        notify("Metamask not found, please install metamask to continue");
      }
      const isVotable = await isCultMandator();
      setIsCMandator(isVotable);
    };
    v();
  }, []);
  const notify = (Message) => toast(Message);

  return (
    <>
      <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
        <Link
          to="/pendingProposal"
          className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
        >
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-8">
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              Pending
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              Acts
            </p>
          </div>
        </Link>
        <Link
          to="/closedProposal"
          className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
        >
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-8">
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              Completed
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              Acts
            </p>
          </div>
        </Link>
      </div>
      <div className="flex flex-col sm:min-h-60 min-h-50 justify-evenly h-full">
        <Link
          to="/submitProposal"
          className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
        >
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-8">
            <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie sm:font-semibold font-bold">
              Submit
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie sm:font-semibold font-bold">
              Act of Revolt
            </p>
          </div>
        </Link>
        <div
          // to="/delegate"
          className="flex relative 3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
        >
          <img
            className="3xl:w-108 2xl:w-80 3xl:h-56 2xl:h-40 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 3xl:h-40 2xl:h-28 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-4xl 2xl:text-3xl text-sm sm:text-xl font-Nixie sm:font-semibold font-bold">
              CULTmander
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              {/* {parseFloat(totalVote).toLocaleString()} */}
              {isCMandator ? "Yes" : "No"}
              {/* Coming Soon */}
            </p>
          </div>
          <BackButton to="" removeUnderline={true} />
        </div>
      </div>
    </>
  );
}

export default ProposalOptions;
