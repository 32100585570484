import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Header from "./Components/Header";
import Stake from "./Components/Stake/Stake";
import Migrate from "./Components/Migrate/Migrate";
import ProposalOptions from "./Components/Proposal/ProposalOptions";
import SubmitProposal from "./Components/Proposal/SubmitProposal";
import PendingProposal from "./Components/Proposal/PendingProposal";
import ClosedProposal from "./Components/Proposal/ClosedProposal";
import ProposalDetail from "./Components/Proposal/ProposalDetail";
import detectEthereumProvider from "@metamask/detect-provider";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PopUpModal from "./Common/PopUpModal";

import {
  allProposal,
  proposalLoading,
  setMetamask,
  setNetworkId,
  setDashboard,
  selectProposal,
  setMandersList,
  setDashboardLoading,
} from "./features/counter/proposalSlice";
import {
  getProposalFromAPIorLocalStorage,
  totalInvestment,
} from "./Common/governanceFunctions";

import {
  cultBoughtandBurned,
  hexToNumber,
  poolLen,
  totalBurned,
  totalRvltStaked,
  totalStaked,
  treasuryBalance,
} from "./Common/rvltFunctions";
import { ToastContainer } from "react-toastify";
import CheckCultMander from "./Components/CultMander/CheckCultMander";
import CultManderLIst from "./Components/CultMander/CultManderLIst";
import NFTholderList from "./Components/CultMander/NFTholderList";
import { comingSoon } from "./Common/config";
import SocialLInks from "./Components/SocialLInks";
import CheckOptMander from "./Components/OptMander/CheckOptMander";
import { bubbleSort, fetchSelection, fetchSortSession } from './Common/Utils'
var Web3 = require("web3");

const web3 = new Web3(Web3.givenProvider || "ws://localhost:3000");
// const infuraWeb3 = new Web3(process.env.REACT_APP_INFURA_URL);

function App() {
  const dispatch = useDispatch();

  const location = useLocation();

  const setProposalList = async () => {
    const newP = await getProposalFromAPIorLocalStorage()
    const selectedProposals = await fetchSelection(newP)
    const session = await fetchSortSession()
    if (!session) {
      dispatch(allProposal(selectedProposals))
      return
    }
    const sortedArr = await bubbleSort(
      selectedProposals,
      selectedProposals.length,
      session.sortParameter,
      session.sortingMode
    )
    dispatch(allProposal(sortedArr))
  }

  const { dashboard, dashboardLoading } = useSelector(selectProposal);
  const { cultBB } = dashboard.cData;
  const [cycleKeyword, setCycleKeyword] = useState('');

  useEffect(() => {
    const detectMetamask = async () => {
      // this returns the provider, or null if it wasn't detected
      const provider = await detectEthereumProvider();
      if (provider) {
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
          dispatch(setMetamask(false));
          return "";
        }
        // Access the decentralized web!
        // window.web3 = new Web3(window.ethereum);
        const chainId = await web3?.eth?.getChainId();
        dispatch(setNetworkId(hexToNumber(chainId)));
        localStorage.setItem("chainId", hexToNumber(chainId));
        dispatch(setMetamask(true));
      } else {
        dispatch(setMetamask(false));
      }
    };
    detectMetamask();
  }, []);

  useEffect(() => {
    if (window && window.ethereum) {
      window.ethereum.on("accountsChanged", (a) => window.location.reload());
      window.ethereum.on("chainChanged", (chainId) => {
        localStorage.setItem("chainId", hexToNumber(chainId));
        dispatch(setNetworkId(hexToNumber(chainId)));
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    const proposal = async () => {
      dispatch(proposalLoading(true));
      await setProposalList();
      dispatch(proposalLoading(false));
    };
    proposal();
  }, []);

  useEffect(() => {
    const setClosedProposalList = async () => {
      // let cpId = localStorage.getItem("closedProposalsId");
      let closedProposalsId = [];
      let closedProposals = [];
      let cProposal;
      try {
        const newData = await fetch(
          "https://revoltapi.cultdao.io/static/proposals.json"
        );
        cProposal = await newData.json();
      } catch (e) {}

      if (cProposal) {
        try {
          for (const element of cProposal.PROPOSALS) {
            if (
              element.stateName === "Canceled" ||
              element.stateName === "Defeated" ||
              element.stateName === "Expired" ||
              element.stateName === "Executed"
            ) {
              closedProposalsId.push(parseInt(element.id));
              closedProposals.push(element);
            }
          }

          localStorage.setItem(
            "closedProposalsId",
            JSON.stringify(closedProposalsId)
          );
          localStorage.setItem("proposals", JSON.stringify(closedProposals));
        } catch (e) {}
      }
    };
    setTimeout(setClosedProposalList, 4000);
  }, []);

  // const { proposals } = useSelector(selectProposal);

  useEffect(() => {
    const rvltD = async () => {
      let totalStakedAmount = 0;
      let totalRVLTstaked;
      let cultBurned;
      let totalTreasuryBalance;
      let totalInvestmentFund = 0;
      let cultEthPrice;
      let APR;
      let CURRENT_PRICE;
      let GAURDIAN_AMOUNT = 12000000000;
      let MC;
      let cultBB;
      let currentClaimCycle = 0;

      let cData;

      dispatch(setDashboardLoading(true));
      try {
        const data = await fetch(
          "https://revoltapi.cultdao.io/static/statistics.json"
        );
        cData = await data.json();
        if (cData.CURRENT_PRICE) {
          cData.CURRENT_PRICE = parseFloat(cData.CURRENT_PRICE).toFixed(10);
        }
      } catch (e) {
        cData = { totalStakedAmount };
      }

      if (!cData) {
        const tokenResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=USD"
        );
        let tokenPrice = await tokenResponse.json();
        CURRENT_PRICE = tokenPrice["cult-dao"].usd;

        const cultData2 = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=eth"
        );
        let data2 = await cultData2.json();
        cultEthPrice = data2["cult-dao"]["eth"];

        const mcApiData = await fetch(
          "https://api.coingecko.com/api/v3/coins/cult-dao"
        );
        let mcData = await mcApiData.json();
        MC = mcData.market_data.market_cap.usd;

        APR = 1;
        totalStakedAmount = await totalStaked();
        totalRVLTstaked = await totalRvltStaked();
        cultBurned = await totalBurned();
        totalTreasuryBalance = await treasuryBalance();
        totalInvestmentFund = await totalInvestment();
        cultBB = await cultBoughtandBurned();
        currentClaimCycle = (await poolLen()) - 1;

        cData = {
          totalStakedAmount,
          totalRVLTstaked,
          cultBurned,
          totalTreasuryBalance,
          totalInvestmentFund,
          GAURDIAN_AMOUNT,
          APR: 0,
          CURRENT_PRICE: 0,
          cultEthPrice: 0,
          MC: 0,
          cultBB,
          currentClaimCycle,
        };

        dispatch(
          setDashboard({
            cData,
          })
        );
      } else {
        if (!cData.totalStakedAmount) {
          totalStakedAmount = await totalStaked();
          cData = { ...cData, totalStakedAmount };
        }
        if (!cData.totalRVLTstaked) {
          totalRVLTstaked = await totalRvltStaked();
          cData = { ...cData, totalRVLTstaked };
        }
        if (!cData.cultBurned) {
          cultBurned = await totalBurned();
          cData = { ...cData, cultBurned };
        }
        if (!cData.totalTreasuryBalance) {
          totalTreasuryBalance = await treasuryBalance();
          cData = { ...cData, totalTreasuryBalance };
        }
        if (!cData.totalInvestmentFund) {
          totalInvestmentFund = await totalInvestment();
          cData = { ...cData, totalInvestmentFund };
        }
        if (!cData.MC) {
          const mcApiData = await fetch(
            "https://api.coingecko.com/api/v3/coins/cult-dao"
          );
          let mcData = await mcApiData.json();
          MC = mcData.market_data.market_cap.usd;
          cData = { ...cData, MC: 0 };
        }
        if (!cData.CURRENT_PRICE) {
          const tokenResponse = await fetch(
            "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=USD"
          );
          let tokenPrice = await tokenResponse.json();
          CURRENT_PRICE = tokenPrice["cult-dao"].usd;
          cData = { ...cData, CURRENT_PRICE: 0 };
        }
        if (!cData.GAURDIAN_AMOUNT) {
          // GAURDIAN_AMOUNT = await Guardianship();
          cData = { ...cData, GAURDIAN_AMOUNT };
        }
        if (!cData.cultEthPrice) {
          const cultData2 = await fetch(
            "https://api.coingecko.com/api/v3/simple/price?ids=cult-dao&vs_currencies=eth"
          );
          let data2 = await cultData2.json();

          cultEthPrice = data2["cult-dao"]["eth"];
          cData = { ...cData, cultEthPrice: 0 };
        }
        if (!cData.APR) {
          APR = 0;
          cData = { ...cData, APR: 0 };
        }
        if (!cData.currentClaimCycle) {
          currentClaimCycle = (await poolLen()) - 1;
          cData = { ...cData, currentClaimCycle };
        }
      }
      
      dispatch(
        setDashboard({
          cData,
        })
      );
      dispatch(setDashboardLoading(false));
    };
    rvltD();
    setTimeout(rvltD, 2000);
  }, []);

  useEffect(() => {
    const manderList = async () => {
      const data = await fetch(
        "https://revoltapi.cultdao.io/static/manders.json"
      );
      const list = await data.json();
      dispatch(setMandersList(list));
    };
    setTimeout(manderList, 15000);
  }, []);

  useEffect(() => {
    setInterval(() => {
      const reloadData = async () => {
        await setProposalList();
      };
      reloadData();
    }, 1800000);
  }, []);

  useEffect(() => {
    const getCycleKeyword = async () => {
      const data = await fetch("https://revoltapi.cultdao.io/proposal/word");
      const cycleWord = await data.json();
      setCycleKeyword(cycleWord);
    };
    getCycleKeyword();
  }, []);
  
  return (
    <div className="App overflow-hidden  h-full w-full ">
      <Header />
      <div className="4xl:mt-0 3xl:mt-8 flex flex-col w-full h-full">
        <div className="2xl:mt-6 2xl:w-10/12 2xl:mb-0 sm:w-8/12 relative w-full m-auto sm:max-h-70 sm:min-h-75 max-h-125 min-h-115 flex sm:justify-between justify-evenly">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="stake" element={<Stake />} />
            <Route path="migrate" element={<Migrate />} />
            <Route path="checkOptMander" element={<CheckOptMander />} />
            <Route path="proposalOptions" element={<ProposalOptions />} />
            <Route path="submitProposal" element={<SubmitProposal />} />
            <Route path="pendingProposal" element={<PendingProposal />} />
            <Route path="closedProposal" element={<ClosedProposal />} />
            <Route path="proposalDetail" element={<ProposalDetail />} />
            <Route path="checkCultMander" element={<CheckCultMander />} />
            <Route path="CULTmanderList" element={<CultManderLIst />} />
            <Route path="NFTholderList" element={<NFTholderList />} />
          </Routes>
        </div>
        <div
          className={` w-full m-auto 4xl:mt-24 2xl:mt-10 sm:mt-8 mt-3 sm:fixed sm:bottom-5 flex justify-center sm:my-0 sm:mx-auto
           ${
             location?.pathname === "/" ||
             location?.pathname === "/proposalOptions"
               ? "-mt-4"
               : ""
           }`}
        >
          {location?.pathname === "/" ||
          location?.pathname === "/proposalOptions" ? (
            <div className="flex flex-col w-full m-auto items-center">
              <div className="flex relative 3xl:w-108 2xl:w-96 3xl:h-52 2xl:h-52 1xl:w-72 sm:w-64 w-56 h-38">
                <img
                  className="3xl:w-108 2xl:w-96 3xl:h-52 2xl:h-52 sm:w-72 w-56 h-38"
                  src="images/smallPaperCover.svg"
                  alt="not found"
                />
                {location?.pathname === "/" ? (
                  <div className="font-serif flex flex-col 3xl:w-108 2xl:w-96 3xl:h-46 2xl:h-32 md:-top-1 sm:w-64 1xl:w-72 w-56 absolute 1xl:top-1 items-center justify-evenly sm:h-16 h-14 sm:mt-10 mt-8">
                    <p className=" 3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
                      CULT Bought and Burned
                    </p>
                    {comingSoon && !parseInt(cultBB) && dashboardLoading ? (
                      <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
                    ) : (
                      <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold dashboard-statistics">
                        {cultBB && parseInt(cultBB) ? (
                          parseFloat(cultBB).toLocaleString()
                        ) : (
                          <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
                            Coming Soon
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="font-serif flex flex-col 3xl:w-108 2xl:w-96 3xl:h-46 2xl:h-32 md:-top-1 sm:w-64 1xl:w-72 w-56 absolute 1xl:top-1 items-center justify-evenly sm:h-16 h-14 sm:mt-10 mt-8">
                    <p className=" 3xl:text-3xl 2xl:text-2xl sm:text-lg text-xs font-thin font-Nixie font-semibold">
                      Cycle Keyword
                    </p>
                    <PopUpModal />
                    {false ? (
                      <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
                    ) : (
                      <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-lg font-Nixie font-semibold">
                        {cycleKeyword}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <SocialLInks />
            </div>
          ) : (
            <SocialLInks />
          )}
        </div>
      </div>
      <img
        className="background -z-40 object-cover  h-full w-full"
        src="images/background.png"
        alt="Not found"
      />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        // toastClassName={css({ fontFamily: "Times New Roman, Serif" })}
      />
    </div>
  );
}

export default App;
