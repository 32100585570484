import React, { useEffect, useState } from "react";
import {
  isCultMandatorWithAddress,
} from "../../Common/rvltFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PaperCover from "../Covers/PaperCover";
import { Link } from "react-router-dom";
import BackButton from "../BackButton";
import { correctNetworkId, isButtonDisabled } from "../../Common/config";
import PopUpModal from "../../Common/PopUpModal";

function CheckCultMander() {
  const { isMetamask, currentNetworkId } = useSelector(selectProposal);
  const [transactionInProgress, setProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [cycleKeyword, setCycleKeyword] = useState('');

  const initialValues = {
    check: "",
  };
  const [values, setValues] = useState(initialValues);

  const notify = (Message) => toast(Message);

  const data = async () => {
    if (!isMetamask) {
      notify("Metamask not found, please install metamask to continue");
    }
  };
  useEffect(() => {
    data();
  }, []);

  useEffect(() => {
    if (currentNetworkId !== correctNetworkId) {
      notify("Wrong Network");
    }
  }, [currentNetworkId]);

  const handleInputChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    if (!value) {
      setMessage("");
    }
  };

  const checkAddress = async () => {
    setProgress(true);
    const check = await isCultMandatorWithAddress(values.check);

    if (check) {
      setMessage("CULTmander");
    } else {
      setMessage("Not a CULTmander");
    }
    setProgress(false);
    data();
  };

  useEffect(() => {
    const getCycleKeyword = async () => {
      const data = await fetch("https://revoltapi.cultdao.io/proposal/word");
      const cycleWord = await data.json();
      setCycleKeyword(cycleWord);
    };
    getCycleKeyword();
  }, []);

  return (
    <PaperCover isRotate={true}>
      <div className="flex 1xl:mt-20">
        <p className="font-Nixie font-semibold text-lg lg:text-2xl 1xl:text-4xl 3xl:text-5xl">
          CULTmander Check
        </p>
      </div>
      <div className="flex flex-col w-full text-center">
        <div className="flex m-auto">
          <p className="mt-1 3xl:text-4xl 2xl:text-3xl lg:text-lg 1xl:text-1xl text-base font-extralight font-Nixie">
            Cycle Keyword <PopUpModal infoPositionPrevent={"true"} /> : {cycleKeyword}
          </p>
        </div>
        <div className="flex m-auto 1xl:mt-4">
          <p className="mt-1 3xl:text-4xl 2xl:text-3xl lg:text-lg 1xl:text-3xl text-base font-extralight font-Nixie">
            Enter Address
          </p>
        </div>
        <div className="relative 1xl:mt-3">
          <input
            className="3xl:h-14 3xl:mt-3 2xl:h-12 2xl:mt-2 pl-2 w-11/12 mx-auto mb-3 lg:h-9 h-7 border rounded-lg font-Nixie border-gray-600 font-bold 3xl:text-4xl 2xl:text-3xl lg:text-lg text-base "
            name="check"
            value={values.check}
            onChange={handleInputChange}
          />
        </div>
        <button
          className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 1xl:h-12 1xl:w-56 1xl:text-3xl 1xl:mt-3  border w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
          onClick={() => checkAddress()}
          disabled={
            transactionInProgress ||
            currentNetworkId !== correctNetworkId ||
            isButtonDisabled
          }
        >
          Check
        </button>
        {!transactionInProgress ? (
          values.check ? (
            <div className="text-center">
              <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 text-lg ">
                {message}
              </p>
            </div>
          ) : (
            ""
          )
        ) : (
          <AiOutlineLoading3Quarters className="3xl:w-10 3xl:h-10 3xl:mt-5 animate-spin w-6 h-6" />
        )}
      </div>

      <div className="flex w-full justify-between 1xl:mt-2">
        <Link
          to="/CULTmanderList"
          className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 1xl:text-3xl sm:text-xl text-base font-extralight border-b-2  font-Nixie border-gray-500 pb-0"
        >
          CULTmanders List
        </Link>
        <Link
          to="/NFTholderList"
          className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 1xl:text-3xl sm:text-xl text-base font-extralight border-b-2  font-Nixie border-gray-500 pb-0"
        >
          NFT List
        </Link>
      </div>

      <BackButton to="stake" />
    </PaperCover>
  );
}

export default CheckCultMander;
