import React from 'react'

const CompleteSort = ({
  handleSort,
  handleDropDown,
  activeStatus,
  idSort,
  setIdSort
}) => {
  return (
    <div
      className={`dropdown-color absolute top-7 sm:top-9 p-5 rounded-md border border-black`}
    >
      <div
        onClick={async () => {
          idSort === 'ascending'
            ? setIdSort('descending')
            : setIdSort('ascending')
          handleSort('id', 'id', idSort)
          handleDropDown()
        }}
        className={`cursor-pointer ${activeStatus?.id ? 'text-red-800' : ''}`}
      >
        ID #
      </div>
      <div
        onClick={async () => {
          handleSort('totalVotes', 'voteLow', 'ascending')
          handleDropDown()
        }}
        className={`cursor-pointer ${
          activeStatus?.voteLow ? 'text-red-800' : ''
        }`}
      >
        Total votes: Low-High
      </div>
      <div
        onClick={async () => {
          handleSort('totalVotes', 'voteHigh')
          handleDropDown()
        }}
        className={`cursor-pointer ${
          activeStatus?.voteHigh ? 'text-red-800' : ''
        }`}
      >
        Total votes: High-Low
      </div>
    </div>
  )
}

export default CompleteSort
