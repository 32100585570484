import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useSelector } from "react-redux";
import { comingSoon } from "../../Common/config";
import { selectProposal } from "../../features/counter/proposalSlice";

function DElement({ name, value, symbol }) {
  const { dashboardLoading } = useSelector(selectProposal);

  return (
    <div className="flex relative 3xl:w-108 2xl:w-96 3xl:h-52 2xl:h-44 1xl:h-32 sm:w-56 1xl:w-60 w-42 h-28">
      <img
        className="3xl:w-108 2xl:w-96 3xl:h-52 2xl:h-44 sm:w-56 1xl:w-60 1xl:h-32 w-42 h-28"
        src="images/smallPaperCover.svg"
        alt="not found"
      />
      <div className="font-serif flex flex-col 3xl:w-108 2xl:w-96 3xl:h-36 2xl:h-28 sm:w-56 1xl:w-60 1xl:h-15 1xl:top-1 w-42 absolute items-center justify-evenly sm:h-14 h:10 sm:mt-7 mt-9">
        <p className="3xl:text-3xl 2xl:text-2xl sm:text-sm text-xs  font-thin font-Nixie">
          {name}
        </p>
        <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl statistics font-Nixie font-semibold">
          {comingSoon && !value && dashboardLoading ? (
            <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
          ) : value ? (
            symbol === "$" ? (
              symbol + value
            ) : (
              value + symbol
            )
          ) : (
            <p>Coming Soon</p>
          )}
        </p>
      </div>
    </div>
  );
}

export default DElement;
