import React, { useEffect, useState } from "react";
import {
  checkOptMember,
  submitOptin,
  getPriceOptPool,
  getUserBalance,
  revoltPriceInUsd,
} from "../../Common/rvltFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PaperCover from "../Covers/PaperCover";
import BackButton from "../BackButton";
import { correctNetworkId, isButtonDisabled } from "../../Common/config";

function CheckOptMander() {
  const { isMetamask, currentNetworkId } = useSelector(selectProposal);
  const [transactionInProgress, setProgress] = useState(false);
  const [usdbalance, setUsdbalance] = useState();
  const [message, setMessage] = useState("");
  const [validPrice, setValidPrice] = useState();
  const [optinMember, setOptinMember] = useState(false);

  const initialValues = {
    check: "",
  };
  const [values, setValues] = useState(initialValues);

  const notify = (Message) => toast(Message);

  const data = async () => {
    if (!isMetamask) {
      notify("Metamask not found, please install metamask to continue");
    }
  };
  useEffect(() => {
    const getBalance = async () => {
      const balance = await getUserBalance();
      const usdBalance = await revoltPriceInUsd(balance);
      setUsdbalance(usdBalance);
    };
    const getPrice = async () => {
      const response = await getPriceOptPool();
      setValidPrice(response);
    };
    const checkOptinStatus = async () => {
      const response = await checkOptMember();
      setOptinMember(response);
    };
    getBalance();
    checkOptinStatus();
    getPrice();
  }, []);
  useEffect(() => {
    data();
  }, []);

  useEffect(() => {
    if (currentNetworkId !== correctNetworkId) {
      notify("Wrong Network");
    }
  }, [currentNetworkId]);

  const handleInputChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    if (!value) {
      setMessage("");
    }
  };

  const submitAddress = async () => {
    setProgress(true);
    if (optinMember) {
      notify("Already Optin member.");
      setProgress(false);
      return;
    }

    if (!usdbalance || usdbalance < validPrice) {
      notify("Insufficient funds.");
      setProgress(false);
      return;
    }

    const check = await submitOptin();
    if (check.code === 4001) {
      notify("Transaction Rejected");
    } else {
      notify("Transaction Confirmed");
    }
    setProgress(false);
    data();
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <PaperCover isRotate={true}>
        <div className="flex 1xl:mt-12">
          <p className="font-Nixie font-semibold text-xl sm:text-2xl lg:text-2xl  1xl:text-4xl 3xl:text-5xl">
            Opt-In pool
          </p>
        </div>
        <div className="text-center" style={{width:"75%"}}>
          <p>Submit the transaction below to opt-in to the CULTmander pool.</p>
          <p>
            Note: it is required to have $150 RVLT staked at the time of opting
            in. Unstaking any amount will remove you from the pool.
          </p>
          {/* <p className='3xl:text-4xl 2xl:text-3xl lg:text-2xl 1xl:text-3xl text-base font-extralight font-Nixie '>
            {optinMember ? 'Already Optin member' : 'Be Optin member'}
          </p> */}
        </div>
        <div className="flex items-center w-full text-center">
          <button
            className="relative gap-2 px-2 flex items-center justify-center 3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 1xl:h-12 1xl:w-56 1xl:text-3xl 1xl:mt-3  border w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium w-[fit-content]"
            onClick={() => submitAddress()}
            disabled={
              transactionInProgress ||
              currentNetworkId !== correctNetworkId ||
              isButtonDisabled
            }
          >
            Submit
            {transactionInProgress && (
              <AiOutlineLoading3Quarters className="relative 3xl:w-10 3xl:h-10 3xl:mt-5} animate-spin w-6 h-6" />
            )}
          </button>
        </div>

        <BackButton to="stake" />
      </PaperCover>
      <div className="flex relative -top-10 sm:-top-32 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
        <img
          className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-40 h-32"
          src="images/smallPaperCover.svg"
          alt="not found"
        />
        <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-8 mt-9">
          <p className="3xl:text-4xl 2xl:text-3xl  text-md sm:text-xl font-Nixie font-semibold">
            OPTmander
          </p>
          <p className="3xl:text-4xl 2xl:text-3xl  text-md sm:text-xl font-Nixie font-semibold">
            {optinMember ? "Yes" : "No"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CheckOptMander;
