import React from "react";
import StakingCard from "./StakingCard";
import BackButton from "../BackButton";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link } from "react-router-dom";
import { comingSoon } from "../../Common/config";
import PopUpModal from "../PopUpModal";
import ShortPaperCover from '../Covers/ShortPaperCover'

function Stake() {
  // const [totalStakedAmount, setTotalStake] = useState(0);
  const { dashboard, dashboardLoading } = useSelector(selectProposal);

  const { APR, totalStakedAmount } = dashboard.cData;

  let { CURRENT_PRICE } = dashboard.cData;
  CURRENT_PRICE = parseFloat(CURRENT_PRICE);

  return (
    <div className=" w-full m-auto lg:max-h-70 lg:min-h-70 flex flex-col lg:flex-row md:justify-between">
      <div className="flex lg:flex-col lg:min-h-70 justify-evenly sm:h-full sm:mt-0 -mt-4">
        <div className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs  font-thin font-Nixie">
              RVLT Price
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold dashboard-statistics">
              {comingSoon && !CURRENT_PRICE && dashboardLoading ? (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              ) : CURRENT_PRICE ? (
                "$" + CURRENT_PRICE?.toFixed(10)
              ) : (
                <p>Coming Soon</p>
              )}
              {}
            </p>
          </div>
        </div>
        <Link
          to="/checkCultMander"
          className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20"
        >
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-4xl 2xl:text-3xl  text-sm sm:text-xl font-Nixie font-semibold">
              Check
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl  text-sm sm:text-xl font-Nixie font-semibold">
              CULTmander
            </p>
          </div>
        </Link>
      </div>
      <div className='flex flex-col items-center'>
        <ShortPaperCover isRotate={false}>
          <StakingCard />
        </ShortPaperCover>
        <Link
          to='/checkOptMander'
          className='flex justify-center relative lg:-top-20 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-28'
        >
          <img
            className='3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 lg:w-56 w-36 h-28'
            src='images/smallPaperCover.svg'
            alt='not found'
          />
          <div className='font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-4'>
            <p className='3xl:text-4xl 2xl:text-3xl text-sm sm:text-sm font-Nixie font-semibold'>
              Opt-In as CULTmander
            </p>
          </div>
        </Link>
      </div>
      <div className="flex lg:flex-col lg:max-h-70 lg:min-h-70 justify-evenly sm:h-full sm:mt-0 -mt-7 sm:mb-3 mb-6">
        <div className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs  font-thin font-Nixie">
              APR
            </p>
            <PopUpModal />
            <p className=" 3xl:text-4xl 2xl:text-3xl text-base sm:text-xl font-Nixie font-semibold">
              {comingSoon && !APR && dashboardLoading ? (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              ) : APR ? (
                APR.toFixed(2) + "%"
              ) : (
                <p>Coming Soon</p>
              )}
            </p>
          </div>
        </div>
        <div className="flex relative 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 sm:h-28 h-20">
          <img
            className="3xl:w-108 2xl:w-80 xl:w-60 3xl:h-52 2xl:h-40 xl:h-32 sm:w-56 w-36 h-28"
            src="images/smallPaperCover.svg"
            alt="not found"
          />
          <div className="font-serif flex flex-col 3xl:w-108 2xl:w-80 xl:w-60 3xl:h-36 2xl:h-28 xl:h-20 sm:w-56 w-36 absolute items-center justify-evenly sm:h-16 h:10 sm:mt-6 mt-9">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl sm:text-sm text-xs statistics  font-thin font-Nixie">
              Total RVLT Staked
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl text-xs sm:text-lg dashboard-statistics font-Nixie font-semibold">
              {comingSoon &&
              !parseInt(totalStakedAmount) &&
              dashboardLoading ? (
                <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
              ) : parseFloat(totalStakedAmount?.toFixed(0)) ? (
                parseFloat(totalStakedAmount?.toFixed(0))?.toLocaleString()
              ) : (
                <p>Coming Soon</p>
              )}
            </p>
          </div>
        </div>
        <BackButton to="" removeUnderline={true} />
      </div>
    </div>
  );
}

export default Stake;
