const mainnet = true;

export const deadAddress1 = "0x000000000000000000000000000000000000dEaD";
export const deadAddress2 = "0xdEAD000000000000000042069420694206942069";

//v1: 0x86C66AedD67C184FDaD4293261F49b069510FF64
export const deadAddress3 = "0x292877C901A129c4330aA845ebC5E4954126d543";

//v1: 0x55AC81186E1A8454c79aD78C615c43f54F87403B
export const uRvltAddress = mainnet
  ? "0x950fc2E8d7BaF6C680183C64050d71A263c250ea"
  : "0x288d03000a4e76985CDF1edA250d649D8aa035b2";

//v1: 0xf0f9D895aCa5c8678f706FB8216fa22957685A13  
export const rvltAddress = mainnet
  ? "0x5d301750cc9719f00872E33Ee81f9C37aBa242F4"
  : "0xe9AaF0231Ea48ed0d3016C01104595C554fe1E43";

//v1: 0x66a37F34cDF6904c24264E4362668fb2449956Cb, 0x4D4eD63dc470259AB369CB29838cc45A9a1BfdAC  
export const governanceAddress = mainnet
  ? "0xBc7D9F8cD79680D314F37380952Eb88Bac1d1D86"
  : "0x4faFf58dB59F214b8F5FC82bA9CF4fA911Ca7c76";
export const treasuryAddress = mainnet
  ? "0x292877C901A129c4330aA845ebC5E4954126d543"
  : "0xCF03A9f9F29098E7C11E14f1F7C114251d62a0E6";

export const governanceNewAddress = mainnet
  ? "0xBc7D9F8cD79680D314F37380952Eb88Bac1d1D86"
  : "0x4faFf58dB59F214b8F5FC82bA9CF4fA911Ca7c76";


//v1: 0x7b3db0B11ca2C4833312540312C720509F5f3013
export const OptAddress = mainnet
  ? "0xfb1F1FC229a74077b05fAe46B5d18deB6911C9F2"
  : "";


// Migration Process

export const oldRVLTCompare = mainnet ? "0xf0f9D895aCa5c8678f706FB8216fa22957685A13" : "0x9923a98508c455d1b297d175c9ff5da8a407c743";
export const olduRVLTCompare = mainnet ? "0x55AC81186E1A8454c79aD78C615c43f54F87403B" : "0x821c17bbfd319e5c422365ca49cfa7c0ca6d993f";
export const migrationAddress = mainnet ? "0x2Cb97A7c5957195F119a40E305c3365fe0B514E9" : "0x2f54e7fE0CCdc2352984E5E4Cf11d3714945371a";