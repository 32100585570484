/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import WalleltConnect from "./WalleltConnect";

function Header() {
  const [show, setShow] = useState(false);

  return (
    <header className="w-full 3xl:h-44 2xl:h-40 xl:h-36  sm:h-28 h-20 flex justify-between items-center bg-black sm:bg-opacity-0 bg-opacity-60">
      <Link
        to="/"
        className="flex relative 3xl:w-96 2xl:w-80 xl:w-60 2xl:h-44 xl:h-36 sm:w-56 w-2/5 h-28"
      >
        <div className="3xl:w-72 3xl:h-56 3xl:-ml-4 3xl:mt-2 absolute 2xl:w-64 sm:w-56 2xl:-top-6   w-36 sm:h-28 h-24 top-0 left-0 flex flex-col items-center">
          <img
            className="3xl:w-96 3xl:ml-28 3xl:mt-10 2xl:w-80 2xl:h-44 2xl:mt-12 2xl:ml-12 xl:w-48 xl:ml-2 xl:mt-10 sm:w-40 w-0 sm:mt-3 sm:visible invisible"
            src="images/Revolt2Earn.png"
            alt="not found"
          />
          <img
            className="w-40 sm:m-auto mt-4 sm:invisible visible"
            src="images/Revolt2Earn.png"
            alt="not found"
          />
        </div>
      </Link>
      <div className="flex sm:w-6/12 w-0 justify-between  sm:visible invisible ">
        <NavLink
          to="/stake"
          className={` decoration-1 text-yellow-50 text-xl pt-5 font-extralight font-Nixie 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl`}
        >
          Stake RVLT
        </NavLink>
        <NavLink
          to="/migrate"
          className={` decoration-1 text-yellow-50 text-xl pt-5 font-extralight font-Nixie 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl`}
        >
          Migrate
        </NavLink>
        <NavLink
          to="/"
          className={` decoration-1 text-yellow-50 text-xl pt-5 font-extralight font-Nixie 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl`}
        >
        </NavLink>
        <NavLink
          to="/checkOptMander"
          className={` decoration-1 text-yellow-50 text-xl pt-5 font-extralight font-Nixie 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl`}
        >
          Opt In Pool
        </NavLink>
        <NavLink
          to="/proposalOptions"
          className={` decoration-1 text-yellow-50 text-xl pt-5 font-extralight font-Nixie 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl`}
        >
          Acts of Revolt
        </NavLink>
      </div>
      <div className="pr-3 relative">
        <WalleltConnect />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 sm:invisible visible text-white ml-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={() => setShow((prev) => !prev)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        {show ? (
          <div className="h-24 w-28 sm:invisible visible flex flex-col justify-evenly items-center absolute top-16 right-0 bg-black sm:bg-opacity-0 bg-opacity-60 z-50">
            <Link
              className="text-white"
              to="/stake"
              onClick={() => setShow(!show)}
            >
              Stake RVLT
            </Link>
            <Link
              className="text-white"
              to="/proposalOptions"
              onClick={() => setShow(!show)}
            >
              Acts of Revolt
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </header>
  );
}

export default Header;
