import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  Execute,
  getProposalFromAPIorLocalStorage,
  Queue,
} from "../../Common/governanceFunctions";
import { useDispatch } from "react-redux";
import Countdown from "react-countdown";
import {
  allProposal,
  currentProposal,
  /* selectProposal, */
} from "../../features/counter/proposalSlice";
import PendingProposalModal from "../Modals/PendingProposalModal";
import { correctNetworkId, isButtonDisabled } from "../../Common/config";
import { updateProposalSession } from "../../Common/Utils";
var Web3 = require("web3");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
// const infuraWeb3 = new Web3(process.env.REACT_APP_INFURA_URL);

function PendingProposalItem({
  details,
  setPendingData,
  pendingData,
  currentAccount,
  isCMandator,
}) {
  const [showModal, setShowModal] = useState(false);
  // const { proposalData, proposals } = useSelector(selectProposal);
  const [endIn, setEndIn] = useState(0);
  // const [isUpdateFlag, setIsUpdateFlag] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setEndInOrStatus(details);
  }, [details]);

  const updateProposal = async () => {
    await fetch("https://revoltapi.cultdao.io/proposal/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const newP = await getProposalFromAPIorLocalStorage();
   
    dispatch(allProposal(newP));
  };

  const displayWallet = (address) => {
    return `${address?.slice(0, 4)}...${address?.slice(
      address?.length - 4,
      address?.length
    )}`;
  };

  const updateId = (id, value) => {
    const canUpdateSession = updateProposalSession(id, value);
    if (canUpdateSession) {
      setPendingData((prev) => {
        const data = prev.map((data) => {
          if (data.id === id && !String(data?.status)?.includes(value)) {
            return { ...data, status: value };
          } else if (data.id === id && String(data?.status)?.includes(value)) {
            return { ...data, status: "" };
          }
          return data;
        });
        return data;
      });
    }
  };

  const approvePercentage = (data) => {
    const favour = parseFloat(data?.forVotes);
    const against = parseFloat(data?.againstVotes);
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }
    return (favour / total) * 100 === 100 || (favour / total) * 100 === 0
      ? (favour / total) * 100
      : ((favour / total) * 100).toFixed(2);
  };

  const rejectPercentage = (data) => {
    const favour = parseFloat(data?.forVotes);
    const against = parseFloat(data?.againstVotes);
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }
    return (against / total) * 100 === 100 || (against / total) * 100 === 0
      ? (against / total) * 100
      : ((against / total) * 100).toFixed(2);
  };

  const setEndInOrStatus = async (data) => {
    // const currentBlock = await web3.eth.getBlockNumber();
    const estimatedTime =
      parseInt(data?.endTime) - parseInt(new Date().getTime() / 1000);

    setEndIn(estimatedTime);
  };

  const makeQueued = async (id) => {
    const q = await Queue(id);
    updateProposal();
  };

  const DoExecute = async (id) => {
    const q = await Execute(id);
    updateProposal();
  };

  // Renderer callback with condition

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          Updating <span className="animate-bounce">...</span>
        </>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {days > 9 ? days : `0${days}`}:{hours > 9 ? hours : `0${hours}`}:
          {minutes > 9 ? minutes : `0${minutes}`}:
          {seconds > 9 ? seconds : `0${seconds}`}
        </span>
      );
    }
  };

  const status = (data) => {
    if (data?.stateName === "Active") {
      return (
        <>
          <span className="font-semibold">
            Ends in:{" "}
            {endIn &&
            localStorage.getItem("chainId") === correctNetworkId.toString() ? (
              <Countdown date={Date.now() + endIn * 1000} renderer={renderer} />
            ) : (
              "N/A"
            )}
          </span>
        </>
      );
    } else if (data?.stateName === "Succeeded") {
      return (
        <>
          {/* Status:{" "} */}
          <button
            type="submit"
            className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-0 2xl:w-32 2xl:h-10 2xl:text-2xl xl:text-xl 2xl:mt-7 border sm:w-24 w-24 sm:h-7 h-6 rounded-lg sm:text-lg text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
            onClick={() => makeQueued(data?.id)}
            disabled={isButtonDisabled}
          >
            Queue
          </button>
        </>
      );
    } else if (data?.stateName === "Queued") {
      const time = new Date().getTime();

      if (data?.eta > time / 1000) {
        return (
          <>
            Status: <span className="font-semibold">{data?.stateName}</span>
          </>
        );
      }
      return (
        <>
          {/* Status:{" "} */}
          <button
            type="submit"
            className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-0 2xl:w-32 2xl:h-10 2xl:text-2xl xl:text-xl 2xl:mt-7 border sm:w-24 w-24 sm:h-7 h-6 rounded-lg sm:text-lg text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
            onClick={() => DoExecute(data?.id)}
            disabled={isButtonDisabled}
          >
            Execute
          </button>
        </>
      );
    } else {
      return (
        <>
          Status: <span className="font-semibold">{data?.stateName}</span>
        </>
      );
    }
  };

  return (
    /* flex flex-col 3xl:text-4xl 2xl:text-3xl xl:text-2xl */
    <div
      className="flex flex-col 3xl:text-4xl 2xl:text-3xl xl:text-2xl hover:bg-gray-100 pl-2 rounded-xl py-2
      "
      onClick={() => {
        dispatch(currentProposal(details));
      }}
      id={details?.id}
    >
      {/* <Link to="/proposalDetail">
        <span className="underline underline-offset-1 mr-1 3xl:mt-3  2xl:text-3xl xl:text-2xl 2xl:mt-3">
          Act of Revolt({details?.id}):
        </span>
        ({details?.description?.proposalName})
      </Link> */}

      <div
        className="cursor-pointer"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <span className="underline underline-offset-1 mr-1 3xl:mt-3  2xl:text-3xl xl:text-2xl 2xl:mt-3">
          Act of Revolt({parseInt(details?.id)}):
        </span>
        ({details?.description?.proposalName})
      </div>

      <div className="flex flex-col  mt-2 ">
        <div className="w-full flex flex-col px-1 gap-3 relative">
          <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row justify-between">
            <div className="flex flex-col">
              <div className="md:text-xl text-lg mb-1">
                Submitted by:
                <span className="font-semibold text-md">
                  <span className="cursor-pointer break-all">{`${details?.description?.socialHandles} / `}</span>
                  <span>
                    <CopyToClipboard
                      text={details?.description?.wallet}
                      onCopy={() => {
                        toast.success("Wallet Address copied.");
                      }}
                    >
                      <span className="cursor-pointer">{`${displayWallet(
                        details?.description?.wallet
                      )}`}</span>
                    </CopyToClipboard>
                  </span>
                </span>
              </div>
              <div className="flex justify-between items-center gap-5 pr-2 md:text-xl text-lg">
                <div className="flex flex-col sm:flex-row ">
                  Amount:
                  <span className="font-semibold">
                    {details?.description?.treasury} Eth
                  </span>
                </div>

                <div className=" flex flex-col sm:flex-row">
                  <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl ">
                    Total Votes :
                  </p>
                  <p className="ml-1 font-bold 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                    {(
                      parseFloat(details?.againstVotes) +
                      parseFloat(details?.forVotes)
                    ).toLocaleString()}
                  </p>
                </div>

                <div className=" flex flex-col sm:flex-row">
                  <div>{status(details)}</div>
                </div>
              </div>
            </div>
            <div>
              {details?.proposer !== currentAccount &&
              !details?.voteCount?.hasVoted &&
              isCMandator &&
              details?.stateName === "Active" ? (
                <div className="flex flex-row lg:flex-col justify-start lg:justify-between gap-2 px-1 relative vote-button-container">
                  <div
                    className={`w-button border border-black rounded-md 
                  ${details?.status === 1 ? "bg-approve" : ""}`}
                    onClick={() => {
                      updateId(details?.id, 1);
                    }}
                  >
                    Approve
                  </div>
                  <div
                    className={`
                  ${details?.status === 0 ? "bg-reject" : ""}
                   w-button border border-black rounded-md`}
                    onClick={() => {
                      updateId(details?.id, 0);
                    }}
                  >
                    Reject
                  </div>
                </div>
              ) : (
                ""
              )}

              {isCMandator && details?.voteCount?.hasVoted ? (
                <div className="flex flex-col sm:flex-row sm:items-center items-start 3xl:mt-3">
                  <div className="3xl:w-96 2xl:mt-3 2xl:w-80 xl:w-60 sm:w-44 w-8/12 flex flex-col mr-4 border rounded border-slate-600">
                    <div className="flex justify-between px-1 border-b border-slate-600 relative font-semibold">
                      <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-1">
                        Approve
                      </p>{" "}
                      <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                        {approvePercentage(details)}%
                      </p>
                      <div
                        style={{ width: `${approvePercentage(details)}%` }}
                        className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
                      ></div>
                    </div>
                    <div className="flex  justify-between px-1 relative font-semibold">
                      <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-1">
                        Reject
                      </p>{" "}
                      <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                        {rejectPercentage(details)}%
                      </p>
                      <div
                        style={{ width: `${rejectPercentage(details)}%` }}
                        className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
                      ></div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* <div className="3xl:mt-6 3xl:mb-6 flex flex-col sm:flex-row sm:items-center items-start justify-between px-1 border-b border-slate-600 relative font-semibold mt-2 ">
          <div className="flex flex-col ">
            <div className="flex text-sm ">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl ">
                Approved :
              </p>
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.forVotes).toLocaleString()}
              </p>
            </div>
            <div className="flex text-sm 3xl:mt-3">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">Rejected :</p>
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.againstVotes).toLocaleString()}
              </p>
            </div>
          </div>
          <div className="flex  text-sm">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">
              Total Votes :{" "}
            </p>
            <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
              {(
                parseFloat(details?.againstVotes) +
                parseFloat(details?.forVotes)
              ).toLocaleString()}
            </p>
          </div>
        </div> */}
      </div>
      <PendingProposalModal
        showModal={showModal}
        details={details}
        setShowModal={setShowModal}
      />
    </div>
  );
}

export default PendingProposalItem;
