import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectProposal } from "../features/counter/proposalSlice";
import { toast } from "react-toastify";
var Web3 = require("web3");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

function WalleltConnect() {
  const { isMetamask } = useSelector(selectProposal);

  const notify = (Message) => toast(Message);
  const [accont, setAccont] = useState("");
  useEffect(() => {
    if (window && window.ethereum && isMetamask) {
      web3.eth
        .requestAccounts()
        .then((a) => setAccont(a))
        .catch((err) => {});
    }
  }, []);
  if (window && window.ethereum) {
    window.ethereum.on("accountsChanged", (a) => setAccont(a));
  }
  
  return (
    <>
      <button
        className="flex relative 3xl:w-64 2xl:w-64 xl:h-44 xl:w-44 sm:w-36 w-2/5 "
        onClick={() =>
          !isMetamask
            ? notify("Metamask not found, please install metamask to continue")
            : web3.eth
                .requestAccounts()
                .then((a) => setAccont(a))
                .catch((err) => {
                  if (err.code === 4001) {
                  } else {
                  }
                })
        }
      >
        <img
          className="3xl:w-64 3xl:mt-8 2xl:w-60 2xl:h-44 xl:w-44 xl:h-44 2xl:mt-6 sm:w-36 w-0  sm:visible invisible "
          src="images/smallPaperCover.svg"
          alt="not found"
        />
        <h1 className="3xl:mt-20 3xl:text-4xl 2xl:mt-16 2xl:ml-6 2xl:text-4xl xl:text-2xl xl:mt-12 xl:ml-5 sm:absolute sm:text-xl sm:left-4 sm:top-6 sm:ml-3 font-Nixie font-semibold m-auto sm:text-themeColor text-themeColor">
          {accont.length ? accont.toString().slice(0, 6) + "..." : "Connect"}
        </h1>
      </button>
    </>
  );
}

export default WalleltConnect;
