import React from "react";
import BackButton from "../BackButton";

function BigPaperCover({ children, to }) {
  return (
    <div className="2xl:-mt-4 3xl:-mt-12 sm:w-8/12 w-full m-auto sm:max-h-75 sm:min-h-75 max-h-125 min-h-115 flex justify-between mt-0 ">
      <div className="items-center relative m-auto mt-0 sm:max-h-75 sm:min-h-75 max-h-125 min-h-115">
        <img
          className="w-full sm:max-h-75 sm:min-h-75 max-h-125 min-h-115"
          src="images/extraLargePaperCover.png"
          alt="not found"
        />
        <div className="absolute top-14 w-full xl:max-h-65 xl:min-h-65  sm:max-h-60 sm:min-h-60 max-h-125 min-h-115 ">
          <div className=" w-10/12 3xl:max-h-65 3xl:min-h-65  sm:max-h-60 xl:max-h-65 xl:min-h-63 sm:min-h-60 flex flex-col items-center m-auto relative h-90">
            {children}
          </div>
        </div>
        <BackButton to={to} />
      </div>
    </div>
  );
}

export default BigPaperCover;
