import { ethers } from "ethers";
import { governanceAddress } from "./Addresses";
import governanceABI from "./ABI/governanceNewABI.json";
import { getAccount, getGasFee } from "./rvltFunctions";
import { correctNetworkId } from "./config";
var Web3 = require("web3");
// const web3 = new Web3("https://rpc-mainnet.maticvigil.com/");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

const infuraWeb3 = new Web3(process.env.REACT_APP_INFURA_URL);

function encodeParameters(types, values) {
  const abi = new ethers.utils.AbiCoder();
  return abi.encode(types, values);
}

export const submitProposal = async (data) => {
  try {
    const proposerWeb3 = new Web3(window.ethereum);
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const governanceContract = new proposerWeb3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const address = encodeParameters(["address"], [data.wallet]);

    const treasury = data.treasury * 10 ** 18;

    const encodedTreasury = encodeParameters(["uint"], [treasury + ""]);
    const extraData = await governanceContract.methods
      .propose(
        [governanceAddress],
        [0],
        ["_setInvesteeDetails(address,uint256)"],
        [address + encodedTreasury.slice(2)],
        JSON.stringify(data)
      )
      .encodeABI();
    const gasData = await getGasFee(extraData, governanceAddress, account[0]);

    const proposal = await governanceContract.methods
      .propose(
        [governanceAddress],
        [0],
        ["_setInvesteeDetails(address,uint256)"],
        [address + encodedTreasury.slice(2)],
        JSON.stringify(data)
      )
      .send({
        from: account[0],
        gas: gasData.gas,
        gasPrice: gasData.avgGasPrice,
      });
    return proposal;
  } catch (error) {
    if (error?.code === 4001) {
      return error;
    } else return { code: 4002 };
  }
};

const desc = (v, i) => {
  try {
    return JSON.parse(v[i - 1].returnValues?.description);
  } catch (e) {
    return "";
  }
};

export const approveOrRejectProposal = async (id, value) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const extraData = await governanceContract.methods
      .castVote(id, value)
      .encodeABI();
    const gasData = await getGasFee(extraData, governanceAddress, account[0]);

    const vote = await governanceContract.methods.castVote(id, value).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });
    return vote;
  } catch (error) {
    return error;
  }
};

export const cancelProposal = async (id) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const extraData = await governanceContract.methods.cancel(id).encodeABI();
    const gasData = await getGasFee(extraData, governanceAddress, account[0]);

    const cancelP = await governanceContract.methods.cancel(id).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });
    return cancelP;
  } catch (error) {
    return error;
  }
};

export const Queue = async (id) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const extraData = await governanceContract.methods.queue(id).encodeABI();
    const gasData = await getGasFee(extraData, governanceAddress, account[0]);

    const vote = await governanceContract.methods.queue(id).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });
    return vote;
  } catch (error) {
    return "";
  }
};

export const Execute = async (id) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const extraData = await governanceContract.methods.execute(id).encodeABI();
    const gasData = await getGasFee(extraData, governanceAddress, account[0]);

    const vote = await governanceContract.methods.execute(id).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });
    return vote;
  } catch (error) {
    return "";
  }
};

export const totalInvestment = async (id) => {
  try {
    const governanceContract = new infuraWeb3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const vote = await governanceContract.methods.nextInvesteeFund().call();
    return vote * 13;
  } catch (error) {
    return "";
  }
};

export const isProposalSubmitable = async () => {
  try {
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const boolRes = await governanceContract.methods.isProposalTime().call();
    return boolRes;
  } catch (error) {
    return error;
  }
};

export const rvltPriceP = async () => {
  try {
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const price = await governanceContract.methods.rvltPriceProposal().call();
    const uRVLT = web3.utils.fromWei(price, "ether");
    return parseFloat(uRVLT);
  } catch (error) {
    return error;
  }
};

export const submitPStartAndEndTime = async () => {
  const governanceContract = new web3.eth.Contract(
    governanceABI,
    governanceAddress
  );
  const gStartTime = await governanceContract.methods
    .governanceStartTime()
    .call();
  try {
    const timestamp = parseInt(new Date().getTime() / 1000);

    const vPeriod = await governanceContract.methods.votingPeriod().call();
    const pPeriod = await governanceContract.methods.proposalPeriod().call();

    const reminder =
      (timestamp - parseInt(gStartTime)) %
      (parseInt(vPeriod) + parseInt(pPeriod));
    const sTime = parseInt(pPeriod) + (timestamp - reminder);
    const endTime = sTime + parseInt(vPeriod);
    return { sTime, endTime };
  } catch (e) {
    return e;
  }
};

export const proposalDetail = async () => {
  try {
    const getProposals = localStorage.getItem("proposals");
    const currentNetworkId = localStorage.getItem("chainId");
    if (parseInt(currentNetworkId) !== correctNetworkId) {
      return JSON.parse(getProposals);
    }
    const closedProposals = getProposals ? JSON.parse(getProposals) : [];
    const allProposals = [];
    let description;
    let closedProposalsId = [];
    let cpIds = localStorage.getItem("closedProposalsId");
    if (cpIds) {
      closedProposalsId = JSON.parse(cpIds);
    }
    let i = closedProposalsId.length + 1;
    const stateOptions = {
      0: "Pending",
      1: "Active",
      2: "Canceled",
      3: "Defeated",
      4: "Succeeded",
      5: "Queued",
      6: "Expired",
      7: "Executed",
    };
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    const proposalCount = await governanceContract.methods
      .proposalCount()
      .call();

    const v = await governanceContract.getPastEvents("ProposalCreated", {
      fromBlock: 0,
      toBlock: "latest",
    });

    for (i; i <= proposalCount; i++) {
      if (!closedProposalsId.includes(i)) {
        let proposalDetails = await governanceContract.methods
          .proposals(i)
          .call();
        let state = await governanceContract.methods.state(i).call();
        let stateName = stateOptions[state];
        description = desc(v, i);
        if (
          stateName === "Canceled" ||
          stateName === "Defeated" ||
          stateName === "Expired" ||
          stateName === "Executed"
        ) {
          closedProposalsId.push(i);
          closedProposals.push({
            ...proposalDetails,
            // voteCount,
            description,
            state,
            stateName,
          });
        } else {
          allProposals.push({
            ...proposalDetails,
            // voteCount,
            description,
            state,
            stateName,
          });
        }
      }
    }
    let key = "id";
    const uniqueClosedProposals = [
      ...new Map(closedProposals.map((item) => [item[key], item])).values(),
    ];
    allProposals.push(...uniqueClosedProposals);
    localStorage.setItem(
      "closedProposalsId",
      JSON.stringify(closedProposalsId)
    );
    localStorage.setItem("proposals", JSON.stringify(uniqueClosedProposals));

    return allProposals;
  } catch (error) {
    return "";
  }
};

export const setProposal = async (PROPOSALS) => {
  const newProposalData = [];
  const currentNetworkId = localStorage.getItem("chainId");
  const account = await getAccount();

  if (
    parseInt(currentNetworkId) === correctNetworkId &&
    PROPOSALS &&
    account.length
  ) {
    const account = await getAccount();
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    );
    for (const element of PROPOSALS) {
      let voteCount;
      if (
        element?.stateName === "Pending" ||
        element?.stateName === "Active" ||
        element?.stateName === "Succeeded" ||
        element?.stateName === "Queued"
      ) {
        voteCount = await governanceContract.methods
          .getReceipt(element.id, account[0])
          .call();
      }
      newProposalData.push({
        ...element,
        voteCount,
      });
    }
    return newProposalData;
  } else if (PROPOSALS) {
    return PROPOSALS;
  }
};

export const getProposalFromAPIorLocalStorage = async () => {
  let newP;
  let cProposal;
  try {
    const newData = await fetch(
      "https://revoltapi.cultdao.io/static/proposals.json"
    );
    cProposal = await newData.json();
  } catch (e) {}
  if (cProposal) {
    
    newP = await setProposal(cProposal?.PROPOSALS);
    let proposalArr = [];
    newP?.map((data) => {
      let obj = { ...data, totalVotes: "", amount: "", status: "" };
      obj.totalVotes = Number(data?.againstVotes) + Number(data?.forVotes);
      obj.amount = data?.description?.treasury;
      obj.status = "";
      proposalArr.push(obj);
      // return data
    });

    return proposalArr;
    /* return newP; */
  } else {
    const proposals = await proposalDetail();
    newP = await setProposal(proposals);
    return newP;
  }
};

export const approveOrRejectAllProposal = async (idArr, valueArr) => {
  try {
    const account = await getAccount()

    if (!account.length) {
      return 0
    }
    const governanceContract = new web3.eth.Contract(
      governanceABI,
      governanceAddress
    )
    const vote = await governanceContract.methods
      .batchCastVote(idArr, valueArr)
      .send({ from: account[0] })
    return vote
  } catch (error) {
    return error
  }
}