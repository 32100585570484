import { toast } from 'react-toastify'

function swap (arr, xp, yp) {
  var temp = arr[xp]
  arr[xp] = arr[yp]
  arr[yp] = temp
}

// An optimized version of Bubble Sort
export function bubbleSort (arr, n, parameter, sortingMode) {
  if (sortingMode === 'ascending') {
    sortAscending(arr, n, parameter)
  } else {
    sortDescending(arr, n, parameter)
  }
  return arr
}

function sortAscending (arr, n, parameter) {
  var i, j
  for (i = 0; i < n - 1; i++) {
    for (j = 0; j < n - i - 1; j++) {
      if (Number(arr[j][parameter]) > Number(arr[j + 1][parameter])) {
        swap(arr, j, j + 1)
      }
    }
  }
}
function sortDescending (arr, n, parameter) {
  var i, j
  for (i = 0; i < n - 1; i++) {
    for (j = 0; j < n - i - 1; j++) {
      if (Number(arr[j][parameter]) < Number(arr[j + 1][parameter])) {
        swap(arr, j, j + 1)
      }
    }
  }
}

export const fetchSelection = givenArr => {
  const sessiondetails = sessionStorage.getItem('selectedProposals')
  if (!!sessiondetails) {
    const parseSession = JSON.parse(sessiondetails)
    const idArr = parseSession?.proposals?.ids
    const statusArr = parseSession?.proposals?.status
    idArr.map((idValue, index) => {
      for (let i = 0; i < givenArr.length; i++) {
        if (givenArr[i].id === idValue) {
          givenArr[i].status = statusArr[index]
          return
        }
      }
    })
  }
  return givenArr
}

export const updateProposalSession = (id, value) => {
  const sessiondetails = sessionStorage.getItem('selectedProposals')
  let canUpdateSession
  if (!!sessiondetails) {
    const parsedSession = JSON.parse(sessiondetails)
    let idArr = parsedSession?.proposals?.ids
    let statusArr = parsedSession?.proposals?.status
    if (!idArr.includes(id)) {
      if (idArr.length >= 50) {
        toast.error('Please select upto 50.')
        canUpdateSession = false
        return canUpdateSession
      }
      idArr.push(id)
      statusArr.push(value)
    } else if (idArr.includes(id) && statusArr[idArr.indexOf(id)] !== value) {
      statusArr[idArr.indexOf(id)] = value
    } else if (idArr.includes(id) && statusArr[idArr.indexOf(id)] === value) {
      const idx = idArr.indexOf(id)
      idArr.splice(idx, 1)
      statusArr = statusArr.filter((data, index) => (index !== idx ? data : ''))
    }
    sessionStorage.setItem(
      'selectedProposals',
      JSON.stringify({
        proposals: {
          ids: idArr,
          status: statusArr
        }
      })
    )
    canUpdateSession = true
  } else {
    sessionStorage.setItem(
      'selectedProposals',
      JSON.stringify({
        proposals: {
          ids: [id],
          status: [value]
        }
      })
    )
    canUpdateSession = true
  }
  return canUpdateSession
}
export const updateSortSession = (
  sortParameter,
  selectParameter,
  sortingMode
) => {
  sessionStorage.setItem(
    'selectedSort',
    JSON.stringify({
      sortParameter,
      selectParameter,
      sortingMode
    })
  )
}
export const fetchSortSession = () => {
  const sessiondetails = sessionStorage.getItem('selectedSort')
  const parseSession = JSON.parse(sessiondetails)
  return parseSession
}
