import React, { useEffect, useState } from "react";
// import BackButton from "../BackButton";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import BigPaperCover from "../Covers/BigPaperCover";
import {
  getProposalFromAPIorLocalStorage,
  isProposalSubmitable,
  rvltPriceP,
  submitProposal,
  submitPStartAndEndTime,
} from "../../Common/governanceFunctions";
import { useSelector, useDispatch } from "react-redux";
import {
  allProposal,
  proposalLoading,
  selectProposal,
} from "../../features/counter/proposalSlice";
import {
  isCultMandator,
  revoltPriceInUsd,
  uRvltBalance,
} from "../../Common/rvltFunctions";
import PopUpModal from "../../Common/PopUpModal";

import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { isButtonDisabled, correctNetworkId } from "../../Common/config";
import Countdown from "react-countdown";

function SubmitProposal() {
  const [transactionInProgress, setProgress] = useState(false);
  const { currentNetworkId } = useSelector(selectProposal);
  const [isPSubmitable, setIsPSubmitable] = useState();
  const [isCMandator, setIsCMandator] = useState();
  const [uRVLTbalance, setUrvltBalance] = useState();
  const [uRVLTinUSD, setUrvltInUSD] = useState();
  const [rvltPriceProposal, setRvltPriceProposal] = useState();
  const [sTime, setSTime] = useState();
  const [sEndTime, setEndTime] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [cycleKeyword, setCycleKeyword] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const notify = (Message) => toast(Message);

  const data = async () => {
    const isPS = await isProposalSubmitable();
    setIsPSubmitable(isPS);
    const canSubmit = await isCultMandator();
    setIsCMandator(canSubmit);
    const uRvltB = await uRvltBalance();
    setUrvltBalance(uRvltB);
    const price = await revoltPriceInUsd(uRvltB);
    setUrvltInUSD(price);
    const rvltPrice = await rvltPriceP();
    setRvltPriceProposal(rvltPrice);
  };

  const d = async () => {
    const isPS = await isProposalSubmitable();
    setIsPSubmitable(isPS);
    // if (!isPS) {

    // }
    setCurrentTime(parseInt(new Date().getTime() / 1000));
    const w = await submitPStartAndEndTime();
    setSTime(w.sTime);
    setEndTime(w.endTime);
  };

  const updateProposal = async () => {
    await fetch("https://revoltapi.cultdao.io/proposal/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const newP = await getProposalFromAPIorLocalStorage();
    dispatch(allProposal(newP));
  };

  useEffect(() => {
    data();
    d();
  }, []);

  useEffect(() => {
    setInterval(() => {
      d();
    }, 20000);
    return "";
  }, []);

  useEffect(() => {
    if (currentNetworkId !== correctNetworkId) {
      notify("Wrong Network");
    }
  }, [currentNetworkId]);

  const validationSchema = Yup.object({
    shortDescription: Yup.string().required("Required"),
    evidenceLinks: Yup.string().required("Required"),
    treasury: Yup.string().required("Required"),
    wallet: Yup.string().min(26).required("Required"),
    proposalName: Yup.string().required("Required"),
    socialHandles: Yup.string().required("Required"),
  });

  const initialValues = {
    shortDescription: "",
    evidenceLinks: "",
    treasury: "",
    wallet: "",
    proposalName: "",
    socialHandles: "",
  };

  const sProposal = async (data) => {
    if (isCMandator) {
      notify("CULTmanders can not submit Proposal");
      return 0;
    }
    if (!parseFloat(uRVLTbalance)) {
      notify("You don't have enough uRVLT to create a proposal");
      return 0;
    }

    if (parseFloat(uRVLTinUSD) < parseFloat(rvltPriceProposal)) {
      notify("You don't have enough uRVLT to create a proposal");
      return 0;
    }
    if (isPSubmitable) {
      setProgress(true);
      const res = await submitProposal(data);

      if (res?.code === 4001) {
        notify("Transaction Rejected");
      } else if (res?.code === 4002) {
        notify("Invalid Address");
      } else {
        notify("Transaction Confirmed");
        navigate("/proposalOptions");
      }
      setProgress(false);
      dispatch(proposalLoading(true));
      updateProposal();
      dispatch(proposalLoading(false));
    } else {
      notify("Can not submit proposal");
    }
  };

  const RadioButton = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <div className="m-3 flex flex-col">
        <div className="flex flex-col items-center justify-center">
          <div>
            <label class="container">
              <input
                {...field}
                {...props}
                disabled={
                  !isPSubmitable ||
                  isButtonDisabled ||
                  currentNetworkId !== correctNetworkId
                }
              ></input>
              <span class="checkmark"></span>
            </label>
          </div>
          <div className="mt-5 flex flex-col items-center">
            <p className="m-0">{props.value}</p>
            <p className="m-0">WETH</p>
          </div>
        </div>
        {meta.touched && meta.error ? (
          <div className="error font-semibold text-sm 2xl:text-2xl 2xl:mt-2">
            {meta.error}
          </div>
        ) : null}
      </div>
    );
  };

  const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <div className="m-3 flex flex-col">
        <label className="mb-2 w-11/12" htmlFor={props.id || props.name}>
          {label}
        </label>
        <input
          className={` ${
            props.type === "checkbox"
              ? "w-6 h-6 mt-4 3xl:w-12 3xl:h-12 2xl:w-8 2xl:h-8"
              : "pl-2 w-11/12 border-2 rounded border-slate-400 3xl:h-16"
          }`}
          {...field}
          {...props}
          disabled={
            !isPSubmitable ||
            isButtonDisabled ||
            currentNetworkId !== correctNetworkId
          }
        />
        {meta.touched && meta.error ? (
          <div className="error font-semibold text-sm 2xl:text-2xl 2xl:mt-2">
            {meta.error}
          </div>
        ) : null}
      </div>
    );
  };

  const TextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="m-3 flex flex-col">
        <label
          className="mb-2 flex flex-col w-11/12"
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
        <textarea
          className="3xl:h-40 w-11/12 border-2 rounded border-slate-400 h-28 pl-2"
          {...field}
          {...props}
          disabled={
            !isPSubmitable ||
            isButtonDisabled ||
            currentNetworkId !== correctNetworkId
          }
        />
        {meta.touched && meta.error ? (
          <div className="error font-semibold text-sm 2xl:text-2xl 2xl:mt-2">
            {meta.error}
          </div>
        ) : null}
      </div>
    );
  };

  const Timerenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // // Render a completed state

      d();

      return <>{/* Updating <span className="animate-bounce">...</span> */}</>;
    } else {
      // Render a countdown
      return (
        <span>
          {days > 9 ? days : `0${days}`}:{hours > 9 ? hours : `0${hours}`}:
          {minutes > 9 ? minutes : `0${minutes}`}:
          {seconds > 9 ? seconds : `0${seconds}`}
        </span>
      );
    }
  };

  useEffect(() => {
    const getCycleKeyword = async () => {
      const data = await fetch("https://revoltapi.cultdao.io/proposal/word");
      const cycleWord = await data.json();
      setCycleKeyword(cycleWord);
    };
    getCycleKeyword();
  }, []);

  return (
    <BigPaperCover to="proposalOptions">
      <div className="flex sm:flex-row flex-col">
        <p className="text-2xl 4xl:text-5xl 3xl:mt-10 3xl:mb-10 2xl:text-5xl 2xl:mt-6 font-semibold font-Nixie sm:mb-3 mb-0 w-full sm:absolute sm:left-0 sm:top-0 relative sm:flex-none sm:justify-start flex justify-center">
          Act of Revolt
        </p>
        <div className="text-center">
          {isPSubmitable ? (
            <p className="sm:absolute 1xl:right-2 right-0 top-1 text-base">
              Status : Active
            </p>
          ) : sEndTime && currentTime ? (
            <p className="sm:absolute 1xl:right-2 right-0 top-2 text-base">
              <Countdown
                date={new Date().getTime() + (sEndTime - currentTime) * 1000}
                renderer={Timerenderer}
              />
            </p>
          ) : (
            <p className="sm:absolute 1xl:right-2 right-0 top-1 text-base">
              Status : Updating...
            </p>
          )}
          {isPSubmitable && sTime && currentTime ? (
            <p className="sm:absolute 1xl:right-2 right-2 top-8 text-base">
              <span className="mr-1"> Voting starts in :</span>
              <Countdown
                date={new Date().getTime() + (sTime - currentTime) * 1000}
                renderer={Timerenderer}
              />
            </p>
          ) : (
            <p className="sm:absolute 1xl:right-2 right-0 top-1 text-base"></p>
          )}
        </div>
      </div>

      <Formik
        className="scroll"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            sProposal(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        <Form className="overflow-y-scroll font-Nixie font-medium text-lg 2xl:text-3xl 3xl:text-4xl sm:mt-14 mt-0">
          <div className="m-3">
            Cycle Keyword <PopUpModal infoPositionPrevent={"true"} /> : {cycleKeyword}
          </div>
          <TextInput
            label="Give a name for your submission."
            name="proposalName"
            type="text"
          />
          <TextInput
            label="What is your social handle? (for each platform if possible)"
            name="socialHandles"
            type="text"
          />
          <TextArea
            label="Summarise your submission, and why it is worthy of reward."
            name="shortDescription"
            type="text"
          />
          <TextInput
            label="Provide links to pictures and evidence of the act, and your involvement."
            name="evidenceLinks"
            type="text"
          />
          <div>
            <div className="w-11/12 flex flex-col ">
              <p className="m-3 w-11/12">
                What allocation of the treasury is your submission worthy of?
              </p>
              {/* <TextInput label="" name="treasury" type="checkbox" /> */}
              <div className="grid grid-cols-3 lg:flex flex-row">
                <RadioButton name="treasury" type="radio" value="0.1" />
                <RadioButton name="treasury" type="radio" value="0.25" />
                <RadioButton name="treasury" type="radio" value="0.5" />
                <RadioButton name="treasury" type="radio" value="0.75" />
                <RadioButton name="treasury" type="radio" value="1" />
                <RadioButton name="treasury" type="radio" value="1.5" />
              </div>
            </div>
            <div className="mx-3 flex flex-col mb-2 w-11/12">
              <p className="underline mb-3">Note: </p>
              <p className="text-sm">40% will go to your designated wallet.</p>
              <p className="text-sm">25% will go to all RVLT stakers.</p>
              <p className="text-sm">25% will be burned.</p>
              <p className="text-sm">5% will buy and burn CULT.</p>
              <p className="text-sm">
                5% will go to CULTmanders that voted as an incentive.
              </p>
            </div>
          </div>
          <TextInput
            label="Provide your address for your 40% reward."
            name="wallet"
            type="text"
          />

          <div className="w-full flex flex-col items-center">
            <button
              type="submit"
              className="2xl:w-48 2xl:h-16 2xl:text-4xl border w-32 h-9 m-auto mt-8 mb-4 rounded-lg text-xl font-Nixie bg-buttonBg border-slate-600 font-semibold"
              disabled={
                currentNetworkId !== correctNetworkId ||
                transactionInProgress ||
                isButtonDisabled ||
                !isPSubmitable
              }
            >
              Submit
            </button>
          </div>
        </Form>
      </Formik>
      {currentNetworkId !== correctNetworkId ? (
        <p className="3xl:text-4xl sm:text-xl text-base">Wrong Network...</p>
      ) : (
        ""
      )}
      {transactionInProgress ? (
        <div className="flex items-center space-x-0">
          <p className="text-lg pr-3">Submitting Proposal</p>
          <span>
            <AiOutlineLoading3Quarters className="animate-spin w-6 h-6" />
          </span>
        </div>
      ) : (
        ""
      )}
      <ToastContainer hideProgressBar={true} />
    </BigPaperCover>
  );
}

export default SubmitProposal;
