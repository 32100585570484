import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BigPaperCover from "../Covers/BigPaperCover";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProposal,
  allProposal,
  proposalLoading,
} from "../../features/counter/proposalSlice";
import {
  approveOrRejectProposal,
  cancelProposal,
  getProposalFromAPIorLocalStorage,
} from "../../Common/governanceFunctions";
import { toast, ToastContainer } from "react-toastify";
import {
  getAccount,
  highestStaker,
  isCultMandator,
} from "../../Common/rvltFunctions";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { correctNetworkId, isButtonDisabled } from "../../Common/config";

function ProposalDetail() {
  const { proposalData, currentNetworkId, customRoute } =
    useSelector(selectProposal);
  const [transactionInProgress, setProgress] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");
  const [isCMandator, setIsCMandator] = useState();
  // const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentNetworkId !== correctNetworkId) {
      notify("Wrong Network");
    }
  }, [currentNetworkId]);

  useEffect(() => {
    const d = async () => {
      const account = await getAccount();
      setCurrentAccount(account[0]);
      const isVotable = await isCultMandator();
      setIsCMandator(isVotable);
    };
    d();
  }, []);
  
  const cancelP = async (id) => {
    setProgressMessage("Canceling Proposal");
    setProgress(true);
    const res = await cancelProposal(id);
    if (res.code === 4001) {
      notify("Transaction Rejected");
    } else {
      notify("Transaction Confirmed");
    }
    setProgress(false);
    dispatch(proposalLoading(true));
    dispatch(proposalLoading(false));
  };

  const approveProposalOrReject = async (id, value) => {
    setProgressMessage("Submitting Vote");
    const account = await getAccount();
    if (!account.length) {
      notify("Please connect with metamask");
      return 0;
    }
    if (value) {
      const checkHighestStaker = await highestStaker();
      if (!checkHighestStaker) {
        setProgress(true);
        const res = await approveOrRejectProposal(id, value);
        if (res.code === 4001) {
          notify("Transaction Rejected");
        } else {
          notify("Transaction Confirmed");
          setProgress(false);
          const newP = await getProposalFromAPIorLocalStorage();
          dispatch(allProposal(newP));
          navigate("/proposalOptions");
        }
        setProgress(false);
        return "";
      } else {
        notify("Guardians cannot vote");
      }
    } else {
      setProgress(true);
      const res = await approveOrRejectProposal(id, value);
      if (res.code === 4001) {
        notify("Transaction Rejected");
      } else {
        notify("Transaction Confirmed");
        setProgress(false);
        navigate("/proposalOptions");
      }
      setProgress(false);
    }
  };
  const notify = (Message) => toast(Message);

  return (
    <BigPaperCover to={customRoute}>
      <p className="4xl:text-6xl 3xl:text-5xl 3xl:mb-10 3xl:mt-8 2xl:text-4xl 2xl:mb-5 2xl:mt-6 text-2xl font-semibold font-Nixie mb-3">
        Act of Revolt
      </p>
      <div
        // style={{ width: "98%" }}
        className="overflow-y-scroll font-Nixie font-medium sm:text-lg text-sm h-11/12 w-full pr-4 pb-2 3xl:text-4xl 2xl:text-3xl 2xl:mt-3 tracking-widest"
      >
        <p className="w-full">
          <span className="underline underline-offset-1 mr-1 2xl:mb-3">
            Act of Revolt:
          </span>{" "}
          ({proposalData?.description?.proposalName})
        </p>
        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Social Handle(s)
          </p>

          <p> {proposalData?.description?.socialHandles}</p>
        </div>
        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Description
          </p>
          <p>{proposalData?.description?.shortDescription}</p>
        </div>
        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Images and Evidence
          </p>

          <p>{proposalData?.description?.evidenceLinks}</p>
        </div>
        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Allocation from Treasury
          </p>
          <p>{proposalData?.description?.treasury} ETH</p>
        </div>
        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Reward Distribution
          </p>
          <p>40% will go to your designated wallet.</p>
          <p>25% will go to all RVLT stakers.</p>
          <p>25% will be burned.</p>
          <p>5% will buy and burn CULT.</p>
          <p>5% will go to CULTmanders that voted as an incentive.</p>
        </div>
        <div className="mt-3">
          <p className="underline underline-offset-1 mr-1 2xl:mb-3">
            Revolutionary Wallet
          </p>
          <p>{proposalData?.description?.wallet}</p>
        </div>

        {proposalData?.proposer === currentAccount &&
        (proposalData?.stateName === "Pending" ||
          proposalData?.stateName === "Active" ||
          proposalData?.stateName === "Succeeded" ||
          proposalData?.stateName === "Queued") ? (
          <div className="w-full flex justify-center">
            <button
              type="submit"
              className="3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 border sm:w-6/12 w-7/12 sm:h-9 h-8 m-auto mt-8 mb-4 rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
              onClick={() => cancelP(proposalData?.id)}
              disabled={isButtonDisabled}
            >
              Cancel Proposal
            </button>
          </div>
        ) : !proposalData?.voteCount?.hasVoted &&
          isCMandator &&
          proposalData?.stateName === "Active" ? (
          <div className="w-11/12 flex items-center m-auto">
            <button
              type="submit"
              className="3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 border sm:w-32 w-28 sm:h-9 h-8 m-auto mt-8 mb-4 rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
              onClick={() => approveProposalOrReject(proposalData?.id, 1)}
              disabled={isButtonDisabled}
            >
              Approve
            </button>
            <button
              type="submit"
              className="3xl:h-16 3xl:w-64 3xl:text-4xl 3xl:mt-10 3xl:mb-10 2xl:h-10 2xl:text-3xl 2xl:mt-7 border sm:w-32 w-28 sm:h-9 h-8 m-auto mt-8 mb-4 rounded-lg sm:text-xl text-base font-Nixie bg-buttonBg border-slate-600 font-semibold"
              onClick={() => approveProposalOrReject(proposalData?.id, 0)}
              disabled={isButtonDisabled}
            >
              Reject
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Link
        to={"/" + customRoute}
        className="text-lg font-extrabold underline font-Nixie absolute -bottom-6 sm:invisible visible"
      >
        Back
      </Link>
      {currentNetworkId !== correctNetworkId ? (
        <p className="sm:text-xl text-base">Wrong Network...</p>
      ) : (
        ""
      )}
      {transactionInProgress ? (
        <div className="flex items-center space-x-0">
          <p className="text-lg pr-3">{progressMessage}</p>
          <span>
            <AiOutlineLoading3Quarters className="animate-spin w-6 h-6" />
          </span>
        </div>
      ) : (
        ""
      )}
      <ToastContainer hideProgressBar={true} />
    </BigPaperCover>
  );
}

export default ProposalDetail;
