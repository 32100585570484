import React from "react";
import _ from "lodash";

function PendingProposalModal({ showModal, setShowModal, details }) {
  let detailsLinks = [];
  if (details?.description && details?.description?.evidenceLinks) {
    detailsLinks = _.split(details?.description?.evidenceLinks, ",");
  }

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto max-w-3xl font-thin w-screen">
              <div className="w-full m-auto flex justify-between mt-0">
                <div className="items-center relative m-auto mt-0">
                  <img
                    className="w-screen h-80 sm:w-full sm:h-full container-height"
                    src="images/extraLargePaperCover.png"
                    alt="not found"
                  />
                  {/* <span className='2xl:text-2xl cursor-pointer'>&#x274C;</span> */}
                  <span
                    className="absolute  top-10 sm:top-12 right-16 sm:right-16 text-4xl sm:text-5xl text-gray-500 font-bold cursor-pointer z-60 hover:text-gray-900"
                    onClick={() => setShowModal(false)}
                  >
                    &#215;
                  </span>
                  <div className="over-flow-x w-11/12 absolute top-14 sm:top-24 h-max-50 font-Nixie overflow-y-auto h-90 sm:h-85 ">
                    <div className="h-full flex flex-col justify-between gap-5 px-5 sm:p-5">
                      <div className="pl-5">
                        <div className="text-xl font-bold">
                          Act Description:
                        </div>
                        <div className="text-lg">
                          {details?.description?.shortDescription}
                        </div>
                      </div>
                      <div className="pl-5">
                        <div className="text-xl font-bold">Evidence Links:</div>
                        <div>
                          {detailsLinks.map(function (value) {
                            return (
                              <a
                                href={_.trim(value)}
                                target="_blank"
                                className="text-lg cursor-pointer break-all"
                                rel="noreferrer"
                              >
                                {`${_.trim(value)}  `}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {/* <div className='2xl:w-12/12 w-10/12 sm:max-h-60 sm:min-h-60 flex flex-col items-center m-auto relative h-90'>
                      <p className='4xl:text-6xl 3xl:text-5xl text-2xl sm:text-4xl border-b-2 border-gray-600  mt-0'>
                        Rewards
                      </p>
                      <p className='4xl:text-5xl 3xl:text-4xl text-xl sm:text-2xl sm:mt-10 mt-5'>
                        Please note rewards are claimable whenever RVLT is sent
                        to the uRVLT contract address. This means rewards will
                        be available sporadically, and not sent daily.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default PendingProposalModal;
