import React from "react";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import BackButton from "../BackButton";

function NFTholderList() {
  const { mandersList } = useSelector(selectProposal);
  return (
    <div className="sm:w-full w-11/12 sm:mt-0 mt-4 border-2 border-black bg-white rounded-lg h-5/12 flex flex-col items-center mb-6">
      <p className="text-2xl font-semibold font-Nixie mb-2 xl:text-3xl 2xl:text-4xl 2xl:mt-6 3xl:text-5xl 3xl:mt-10 4xl:text-6xl 4xl:mt-12 mt-4 underline">
        NFTholders
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-2 sm:overflow-x-hidden overflow-x-scroll  overflow-y-scroll justify-between sm:w-[94%] w-11/12 mt-3 mb-5 h-full">
        {mandersList && mandersList.NFT_OWNERS &&
          mandersList?.NFT_OWNERS.map((mander) => (
            <p className="mr-3">{mander}</p>
          ))}
      </div>
      {/* <div className="text-2xl">Coming Soon</div> */}
      <BackButton to="checkCultMander" />
    </div>
  );
}

export default NFTholderList;
