import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BigBanner from "../Covers/BigBanner";
import { useSelector, useDispatch } from "react-redux";
import Countdown from "react-countdown";
import {
  allProposal,
  proposalLoading,
  selectProposal,
  setCustomRoute,
} from "../../features/counter/proposalSlice";
import {
  approveOrRejectAllProposal,
  getProposalFromAPIorLocalStorage,
  isProposalSubmitable,
  submitPStartAndEndTime,
} from "../../Common/governanceFunctions";
import {
  bubbleSort,
  updateSortSession,
} from "../../Common/Utils";

import PendingProposalItem from "./PendingProposalItem";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { correctNetworkId } from "../../Common/config";
import PendingSort from "../Sorting/PendingSort";
import { getAccount, isCultMandator } from "../../Common/rvltFunctions";

function PendingProposal() {
  const { proposals, loading, currentNetworkId } = useSelector(selectProposal);
  const [pendingData, setPendingData] = useState();
  const dispatch = useDispatch();
  const [isPSubmitable, setIsPSubmitable] = useState();
  const [sTime, setSTime] = useState();
  const [sEndTime, setEndTime] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [showDropDown, setShowDropDown] = useState(false);
  const [currentAccount, setCurrentAccount] = useState();
  const [idSort, setIdSort] = useState("ascending");
  const [isCMandator, setIsCMandator] = useState(false);
  const handleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const [activeStatus, setActiveStatus] = useState({
    id: false,
    amountLow: false,
    amountHigh: false,
    voteLow: false,
    voteHigh: false,
  });

  // const updateProposal = async () => {
  //   dispatch(proposalLoading(true))
  //   const newP = await getProposalFromAPIorLocalStorage()
  //   dispatch(allProposal(newP))
  //   dispatch(proposalLoading(false))
  // }

  const updateProposal = async () => {
    const newP = await getProposalFromAPIorLocalStorage();
    dispatch(allProposal(newP));
  };

  useEffect(() => {
    dispatch(setCustomRoute("pendingProposal"));
    if (loading) {
      updateProposal();
    }
  }, []);

  useEffect(() => {
    const tempPendingData = proposals?.length
      ? proposals.filter(
          (details) =>
            details?.stateName === "Pending" ||
            details?.stateName === "Active" ||
            details?.stateName === "Succeeded" ||
            details?.stateName === "Queued"
        )
      : [];
    setPendingData(tempPendingData);
    dispatch(proposalLoading(false));
  }, [proposals]);

  useEffect(() => {
    const d = async () => {
      const account = await getAccount();
      setCurrentAccount(account[0]);
      const isVotable = await isCultMandator();
      setIsCMandator(isVotable);
    };
    d();
  }, []);

  const voteAll = async () => {
    const idsArr = [];
    const statusArr = [];
    pendingData?.map((data) => {
      if ([1, 0].includes(data?.status)) {
        idsArr.push(data.id);
        statusArr.push(data.status);
      }
    });
    if (idsArr.length > 0) {
      // dispatch(proposalLoading(true))
      const response = await approveOrRejectAllProposal(idsArr, statusArr);
      if (response.code === 4001) {
        notify("Transaction Rejected");
        sessionStorage.clear();
      } else {
        notify("Transaction Confirmed");
        // notify(response.message)
        sessionStorage.clear();
      }
      updateProposal();
    } else {
      notify("Please vote for at least one.");
    }
  };

  const timerData = async () => {
    const isPS = await isProposalSubmitable();
    setIsPSubmitable(isPS);
    setCurrentTime(parseInt(new Date().getTime() / 1000));
    const w = await submitPStartAndEndTime();
    setSTime(w.sTime);
    setEndTime(w.endTime);
  };

  useEffect(() => {
    timerData();
  }, []);

  useEffect(() => {
    setInterval(() => {
      timerData();
    }, 20000);
    return "";
  }, []);

  /* useEffect(() => {
    setInterval(() => {
      checkVoteList();
    }, 20000)
    return ''
  }, []) */

  const Timerenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      timerData();
      return <>{/* Updating <span className="animate-bounce">...</span> */}</>;
    } else {
      // Render a countdown
      return (
        <span>
          {days > 9 ? days : `0${days}`}:{hours > 9 ? hours : `0${hours}`}:
          {minutes > 9 ? minutes : `0${minutes}`}:
          {seconds > 9 ? seconds : `0${seconds}`}
        </span>
      );
    }
  };

  const handleSort = async (
    sortParameter,
    selectParameter,
    sortingMode = "descending"
  ) => {
    dispatch(proposalLoading(true));
    const sort = await bubbleSort(
      pendingData,
      pendingData?.length,
      sortParameter,
      sortingMode
    );
    dispatch(proposalLoading(false));
    setPendingData(sort);
    updateSortSession(sortParameter, selectParameter, sortingMode);
    let statusObj = {};
    Object.getOwnPropertyNames(activeStatus).map((data) => {
      selectParameter === data
        ? (statusObj[data] = true)
        : (statusObj[data] = false);
    });
    setActiveStatus(statusObj);
    // dispatch(proposalLoading(false))
  };

  const notify = (Message) => toast(Message);

  return (
    <BigBanner to="proposalOptions">
      {/* <div className="flex sm:flex-row flex-col"> */}
      <div className="w-full flex sm:flex-row flex-col">
        <div className="w-full flex justify-center sm:justify-between  items-center mb-3 lg:pr-4">
          <div className="sm:block  hidden">
            <div
              className="relative flex justify-between px-3 items-center w-button border border-black rounded-md"
              onClick={handleDropDown}
            >
              <span className="text-md md:text-lg">Sort</span>{" "}
              <span className="relative text-2xl -top-1"> &#x2304;</span>
            </div>
            {showDropDown && (
              <PendingSort
                handleSort={handleSort}
                activeStatus={activeStatus}
                handleDropDown={handleDropDown}
                setIdSort={setIdSort}
                idSort={idSort}
              />
            )}
          </div>

          <p className="4xl:text-6xl 3xl:text-5xl  3xl:mt-10 2xl:text-4xl xl:text-3xl 2xl:mt-6 lg:text-2xl text-xl font-semibold font-Nixie">
            Pending Acts
          </p>

          {!isPSubmitable && sEndTime && currentTime && (
            <div className="w-[fit-content] sm:block  hidden">
              <div
                className="sm:block hidden w-button text-md md:text-lg border border-black rounded-md"
                onClick={voteAll}
              >
                Vote All
              </div>
            </div>
          )}

          {isPSubmitable && sTime && currentTime && (
            <div>
              <p className='sm:absolute 1xl:right-2 right-2 top-2 text-base'>
                <span className='mr-1'>Voting starts in :</span>
                <Countdown
                  date={new Date().getTime() + (sTime - currentTime) * 1000}
                  renderer={Timerenderer}
                />
              </p>
            </div>
          )}

          {/* <div className='text-center'>
          {!isPSubmitable && sEndTime && currentTime && (
            <p className='sm:absolute 1xl:right-2 right-0 top-2 text-base'>
              <span className='mr-1'>Submit Acts of Revolt in :</span>
              <Countdown
                date={new Date().getTime() + (sEndTime - currentTime) * 1000}
                renderer={Timerenderer}
              />
            </p>
          )}

          {isPSubmitable && sTime && currentTime && (
            <p className='sm:absolute 1xl:right-2 right-2 top-2 text-base'>
              <span className='mr-1'>Voting starts in :</span>
              <Countdown
                date={new Date().getTime() + (sTime - currentTime) * 1000}
                renderer={Timerenderer}
              />
            </p>
          )}
        </div> */}
        </div>
        <div className="w-full sm:hidden flex justify-between items-center mb-3">
          <div className="flex flex-col justify-center">
            <div
              className="relative flex justify-between px-3 items-center w-button border border-black rounded-md"
              onClick={handleDropDown}
            >
              <span className="text-md md:text-lg ">Sort</span>{" "}
              <span className="relative text-2xl -top-1"> &#x2304;</span>
            </div>
            {showDropDown && (
              <PendingSort
                handleSort={handleSort}
                activeStatus={activeStatus}
                handleDropDown={handleDropDown}
                setIdSort={setIdSort}
                idSort={idSort}
              />
            )}
          </div>

          {!isPSubmitable && sEndTime && currentTime && (
            <div
              className="w-button text-md md:text-lg border border-black rounded-md"
              onClick={voteAll}
            >
              Vote All
            </div>
          )}
          {isPSubmitable && sTime && currentTime && <div></div>}
        </div>
      </div>

      {/*  <div className=" overflow-y-scroll font-Nixie font-medium sm:text-lg text-sm min-w-full 2xl:max-h-55 max-h-50 sm:mt-14 mt-2"> */}
      <div className="w-full overflow-x-hidden overflow-y-scroll font-Nixie font-medium sm:text-lg text-sm min-w-full 2xl:max-h-55 max-h-50">
        {!loading && pendingData ? (
          pendingData?.length ? (
            pendingData?.map((details) => (
              <div key={details.id}>
                <PendingProposalItem
                  key={details?.id}
                  pendingData={pendingData}
                  details={details}
                  currentAccount={currentAccount}
                  setPendingData={setPendingData}
                  isCMandator={isCMandator}
                />
              </div>
            ))
          ) : (
            <div className="flex sm:w-full justify-evenly items-center">
              {currentNetworkId === correctNetworkId ? (
                <p className="text-xl sm:text-2xl m-auto font-semibold 3xl:text-4xl">
                  No pending acts found
                </p>
              ) : (
                <p className="text-2xl m-auto font-semibold 3xl:text-4xl">
                  Wrong Network
                </p>
              )}
            </div>
          )
        ) : (
          <div className="flex sm:w-5/12 m-auto w-full justify-evenly items-center">
            <p className="3xl:text-6xl  3xl:mt-10 sm:text-2xl text-xl">
              Loading ...
            </p>
            <AiOutlineLoading3Quarters className="3xl:mt-10 3xl:w-16 3xl:h-16 animate-spin sm:w-8 sm:h-8 h-6 w-6" />
          </div>
        )}
      </div>

      <Link
        to="/proposalOptions"
        className="text-lg underline font-Nixie mt-3 absolute -bottom-3 sm:invisible visible"
      >
        Back
      </Link>
      <ToastContainer hideProgressBar={true} />
    </BigBanner>
  );
}

export default PendingProposal;
