import React, { useEffect, useState } from "react";
import {
  migrateRvlt,
  migrateuRVLT,
  getAccount,
  oldRvltBalance,
  olduRvltBalance,
  migrateRvltAllowanceCheck,
  migrateuRvltAllowanceCheck,
  approveOldRvltTokenForMigration,
  approveOlduRvltTokenForMigration,
  userRvltMigrateClaimStatus,
  useruRvltMigrateClaimStatus,
} from "../../Common/rvltFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { correctNetworkId, isButtonDisabled } from "../../Common/config";

function MigrateCard() {
  const { isMetamask, currentNetworkId } = useSelector(selectProposal);
  const [transactionInProgress, setProgress] = useState(false);
  const [userRVLTClaimStatusCheck, setUserRVLTClaimStatusCheck] =
    useState(false);
  const [useruRVLTClaimStatusCheck, setUseruRVLTClaimStatusCheck] =
    useState(false);

  const [merkleProofDetails, setMerkleProofDetails] = useState({});
  const [message, setMessage] = useState("");
  const [currentAccount, setCurrentAccount] = useState();
  const [balance, setBalance] = useState({
    uRvlt: 0,
    rvlt: 0,
    claimableRvlt: "",
  });

  const [oldRvltAllowance, setOldRvltAllowance] = useState(0);
  const [olduRvltAllowance, setOlduRvltAllowance] = useState(0);

  const initialValues = {
    stake: "",
    withdraw: "",
  };
  const [values, setValues] = useState(initialValues);

  const notify = (Message) => toast(Message);
  const { dashboard } = useSelector(selectProposal);
  const { currentClaimCycle } = dashboard.cData;
  
  const getMerkleRoot = async (address) => {
    try {
      const newData = await fetch(
        `https://revoltapi.cultdao.io/merkle/proof/details?walletAddress=${address}`
      );
      const merkleProofDetails = await newData.json();
      setMerkleProofDetails(merkleProofDetails);
    } catch (e) {}
  };

  const getBalanceDetails = async (address) => {
    try {
      const newData = await fetch(
        `https://revoltapi.cultdao.io/balance/details?walletAddress=${address}`
      );
      const merkleProofDetails = await newData.json();
      return merkleProofDetails;
    } catch (e) {
      console.log("e", e);
    }
  };

  const data = async () => {
    let Cbalance = 0;
    let dCbalance = 0;

    let rvltContractBalance = await oldRvltBalance();
    let uRvltContractBalance = await olduRvltBalance();

    let migrateRvltAllowance = await migrateRvltAllowanceCheck();
    let migrateuRvltAllowance = await migrateuRvltAllowanceCheck();

    setOldRvltAllowance(migrateRvltAllowance);
    setOlduRvltAllowance(migrateuRvltAllowance);

    const account = await getAccount();
    setCurrentAccount(account[0]);
    if (account[0] !== "") {
      getMerkleRoot(account[0]);
      const userBalanceDetails = await getBalanceDetails(account[0]);
      if (userBalanceDetails) {
        Cbalance = userBalanceDetails.rvltDisplayBalance;
        dCbalance = userBalanceDetails.uRVLTDisplayBalance;
      }

      const userRVLTClaimStatusCheck = await userRvltMigrateClaimStatus();
      setUserRVLTClaimStatusCheck(userRVLTClaimStatusCheck);

      const useruRVLTClaimStatusCheck = await useruRvltMigrateClaimStatus();
      setUseruRVLTClaimStatusCheck(useruRVLTClaimStatusCheck);
    }

    if (!isMetamask) {
      notify("Metamask not found, please install metamask to continue");
    }

    setBalance({
      uRvlt: dCbalance,
      rvlt: Cbalance,
    });
  };
  useEffect(() => {
    data();
  }, []);

  useEffect(() => {
    if (currentNetworkId !== correctNetworkId) {
      notify("Wrong Network");
    }
  }, [currentNetworkId]);

  /** RVLT migration */
  const executeRvltMigration = async () => {
    let rvltContractBalance = await oldRvltBalance();

    if (parseInt(oldRvltAllowance) <= 0) {
      setProgress(true);
      setMessage("Please approve RVLT to migrate.");
      return;
    }

    if (parseFloat(rvltContractBalance) < parseFloat(merkleProofDetails.rvltDisplayBalance)) {
      setMessage("You do not have RVLT balance to migrate.");
      return;
    }
    
    setProgress(true);
    setMessage("Migrating RVLT");
    const migrateRvltDetails = await migrateRvlt(
      merkleProofDetails.rvltBalance,
      merkleProofDetails.rvltProof
    );
    if (migrateRvltDetails?.code === 4001) {
      notify("Transaction Rejected");
    } else {
      notify("Transaction Confirmed");
    }
    setProgress(false);
    data();
  };
  /** RVLT migration ends */

  /** uRVLT migration */
  const executeuRvltMigration = async () => {
    if (parseInt(olduRvltAllowance) <= 0) {
      setProgress(true);
      setMessage("Please approve uRVLT to migrate.");
      return;
    }
    setProgress(true);
    setMessage("Migrating uRVLT");
    console.log(merkleProofDetails.uRvltBalance, merkleProofDetails.uRvltProof);
    const migrateRvltDetails = await migrateuRVLT(
      merkleProofDetails.uRvltBalance,
      merkleProofDetails.uRvltProof
    );
    if (migrateRvltDetails?.code === 4001) {
      notify("Transaction Rejected.");
    } else {
      notify("Transaction Initiated.");
    }
    setProgress(false);
    data();
  };
  /** uRVLT migration ends */

  /** Approving Old RVLT */
  const executeApproveOldRvltTokenForMigration = async () => {

    if (!currentAccount) {
      notify("Please connect your wallet.");
      return;
    }

    setProgress(true);
    setMessage("Approving RVLT");
    const migrateRvltDetails = await approveOldRvltTokenForMigration();
    if (migrateRvltDetails?.code === 4001) {
      notify("Transaction Rejected");
    } else {
      notify("Transaction Initiated.");
    }
    setProgress(false);
    data();
  };
  /** Approving Old RVLT ends */

  return (
    <>
      <div className="flex flex-col w-full items-center h-full justify-evenly">
        <p className="font-Nixie font-semibold lg:text-2xl text-lg 2xl:mt-12 2xl:text-4xl 3xl:text-5xl 1xl:mt-6">
          Migrate RVLT/uRVLT
        </p>

        <div className="">
          <div className="">
            <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie text-center">
              RVLT Balance
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie text-center">
              {balance?.rvlt
                ? (parseInt(balance?.rvlt * 1000) / 1000).toLocaleString()
                : 0}
            </p>
          </div>

          {parseInt(oldRvltAllowance) === 0 && (
            <button
              className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border 1xl:mb-4 mb-2 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
              onClick={() =>
                executeApproveOldRvltTokenForMigration()
              }
              disabled={
                transactionInProgress ||
                currentNetworkId !== correctNetworkId ||
                isButtonDisabled
              }
            >
              Approve
            </button>
          )}

          {oldRvltAllowance > 0 && !userRVLTClaimStatusCheck && (
            <button
              className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border 1xl:mb-4 mb-2 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
              onClick={() =>
                balance?.uRvlt < 1 && values.withdraw > balance?.uRvlt
                  ? notify("You don't have uRVLT token to withdraw")
                  : executeRvltMigration()
              }
              disabled={currentNetworkId !== correctNetworkId}
            >
              Migrate
            </button>
          )}

          {currentAccount && userRVLTClaimStatusCheck && (
            <p className="font-Nixie font-semibold 3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie text-center">
              <b>Claimed</b>
            </p>
          )}
        </div>

        <div className="">
          <div className="">
            <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
              uRVLT Balance
            </p>
            <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie text-center">
              {balance?.uRvlt
                ? (parseInt(balance?.uRvlt * 1000) / 1000).toLocaleString()
                : 0}
            </p>
          </div>

          {parseInt(olduRvltAllowance) === 0 && (
            <button
              className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border 1xl:mb-4 mb-2 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
              onClick={() => approveOlduRvltTokenForMigration()}
            >
              Approve
            </button>
          )}

          {parseInt(olduRvltAllowance) > 0 && !useruRVLTClaimStatusCheck && (
            <button
              className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border 1xl:mb-4 mb-2 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
              onClick={() => executeuRvltMigration()}
              disabled={currentNetworkId !== correctNetworkId}
            >
              Migrate
            </button>
          )}

          {currentAccount && useruRVLTClaimStatusCheck && (
            <p className="font-Nixie font-semibold 3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight text-center">
              <b>Claimed</b>
            </p>
          )}
        </div>

        {currentNetworkId !== correctNetworkId ? (
          <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 sm:text-xl text-base">
            Wrong Network...
          </p>
        ) : (
          ""
        )}

        {transactionInProgress ? (
          <div className="flex items-center space-x-0">
            <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 text-lg pr-3">
              {message}
            </p>
            <span>
              <AiOutlineLoading3Quarters className="3xl:w-10 3xl:h-10 3xl:mt-5 animate-spin w-6 h-6" />
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default MigrateCard;
