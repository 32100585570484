import React from 'react'

function CompletedProposalModal ({ showModal, setShowModal, details }) {
  return (
    <>
      {showModal ? (
        <>
        <div className='justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative my-6 mx-auto max-w-3xl font-thin w-screen'>
              <div className='w-full m-auto flex justify-between mt-0'>
                <div className='items-center relative m-auto mt-0'>
                  <img
                    className='w-screen h-80 sm:w-full sm:h-full container-height'
                    src='images/extraLargePaperCover.png'
                    alt='not found'
                  />
                  {/* <span className='2xl:text-2xl cursor-pointer'>&#x274C;</span> */}
                  <span
                    className='absolute  top-10 sm:top-12 right-16 sm:right-16 text-4xl sm:text-5xl text-gray-500 font-bold cursor-pointer z-60 hover:text-gray-900'
                    onClick={() => setShowModal(false)}
                  >
                    &#215;
                  </span>
                  <div className='w-11/12 absolute over-flow-x top-14 sm:top-24 h-max-50 font-Nixie overflow-y-auto h-90 sm:h-85 '>
                    <div className='h-full flex flex-col overflow-x-hidden justify-between gap-5 p-5'>
                      <div
                        className='overflow-y-scroll overflow-x-hidden font-Nixie font-medium sm:text-lg text-sm ml-5 h-11/12 w-full pr-4 pb-2 3xl:text-4xl  2xl:text-3xl 2xl:mt-3 tracking-widest'
                      >
                        <p className='w-full'>
                          <span className='underline font-semibold underline-offset-1 mr-1 2xl:mb-3'>
                            Act of Revolt:
                          </span>{' '}
                          ({details?.description?.proposalName})
                        </p>
                        <div className='mt-3'>
                          <p className='underline font-semibold underline-offset-1 mr-1 2xl:mb-3'>
                            Social Handle(s)
                          </p>
                          <p className='break-all'> {details?.description?.socialHandles}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='underline font-semibold underline-offset-1 mr-1 2xl:mb-3'>
                            Description
                          </p>
                          <p>{details?.description?.shortDescription}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='underline font-semibold underline-offset-1 mr-1 2xl:mb-3'>
                            Images and Evidence
                          </p>
                          <p className='break-all'>{details?.description?.evidenceLinks}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='underline font-semibold underline-offset-1 mr-1 2xl:mb-3'>
                            Allocation from Treasury
                          </p>
                          <p>{details?.description?.treasury} ETH</p>
                        </div>
                        <div className='mt-3'>
                          <p className='underline font-semibold underline-offset-1 mr-1 2xl:mb-3'>
                            Reward Distribution
                          </p>
                          <p>40% will go to your designated wallet.</p>
                          <p>25% will go to all RVLT stakers.</p>
                          <p>25% will be burned.</p>
                          <p>5% will buy and burn CULT.</p>
                        </div>
                        <div className='mt-3'>
                          <p className='underline font-semibold underline-offset-1 mr-1 2xl:mb-3'>
                            Revolutionary Wallet
                          </p>
                          <p className='break-all'>{details?.description?.wallet}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </>
  )
}

export default CompletedProposalModal
