import React from "react";

function PopUpModal(props) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <button
        className={`2xl:mt-3 2xl:mr-8 xl:mt-2 h-auto p-0 text-base font-bold ${
          props.infoPositionPrevent ? "" : "absolute sm:top-2 sm:right-10"
        }`}
        style={{ marginTop: "-3px" }}
        onClick={() => setShowModal(true)}
      >
        <span className="2xl:text-2xl text-red-600">&#9432;</span>
      </button>

      {showModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl  font-thin">
              <div className="4xl:w-full 3xl:w-11/12 sm:w-8/12 w-full m-auto  flex justify-between mt-0">
                <div className="items-center relative m-auto mt-0 ">
                  <img
                    className="w-full h-80 sm:h-full"
                    src="images/extraLargePaperCover.png"
                    alt="not found"
                  />
                  <div className="absolute top-14 w-full sm:max-h-60 sm:min-h-60 max-h-125 min-h-115 font-Nixie">
                    <div className="2xl:w-12/12 w-10/12 sm:max-h-60 sm:min-h-60 flex flex-col items-center m-auto relative h-90">
                      <p className="4xl:text-5xl 3xl:text-4xl text-xl sm:text-3xl border-b-2 border-gray-600  mt-0">
                        Cycle Keyword
                      </p>
                      <p className="4xl:text-3xl 3xl:text-2xl text-sm sm:text-xl sm:mt-6 mt-3">
                        When presenting evidence for your Act of Revolt, include
                        an image of the Act containing the Cycle Keyword, so
                        that CULTmanders can verify this is a current Act, and
                        not someone elses work.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default PopUpModal;
