import {
  uRvltAddress,
  rvltAddress,
  deadAddress1,
  deadAddress3,
  treasuryAddress,
  OptAddress,
  migrationAddress,
  oldRVLTCompare,
  olduRVLTCompare
} from "./Addresses";
import uRvltABI from "./ABI/uRvltABI.json";
import rvltABI from "./ABI/rvltABI.json";
import treasuryTokenABI from "./ABI/treasuryTokenABI.json";
import OptABI from './ABI/OptABI.json'
import migrationABI from './ABI/migrationABI.json'
import oldRVLTAbi from './ABI/oldRVLTABI.json'
import olduRVLTAbi from './ABI/olduRVLTABI.json'

var Web3 = require("web3");
// const web3 = new Web3("https://rpc-mainnet.maticvigil.com/");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
const infuraWeb3 = new Web3(process.env.REACT_APP_INFURA_URL);

export const getAccount = async () => {
  try {
    const account = await web3.eth.requestAccounts();
    return account;
  } catch (error) {
    return "";
  }
};

export const hexToNumber = (hex) => web3.utils.hexToNumber(hex);
export const getChainId = async () => {
  try {
    const chainId = await web3?.eth?.getChainId();
    localStorage.setItem("chainId", hexToNumber(chainId));
    const checkNetwork =
      hexToNumber(chainId) === 1 || hexToNumber(chainId) === 56;
    localStorage.setItem("correctNetwork", `${checkNetwork}`);
    return hexToNumber(chainId);
  } catch (e) {
    return "";
  }
};

export const chainChanged = async () => {
  window.ethereum.on("chainChanged", (chainId) => {
    localStorage.setItem("chainId", hexToNumber(chainId));
    window.location.reload();
  });
};

export const getBalance = async (account) => {
  const balance = await web3.eth.getBalance(account);
  return (balance / Math.pow(10, 18)).toFixed(4);
};

export const getGasFee = async (
  extraData,
  contractAddress,
  connectedAddress
) => {
  try {
    const avgGasPrice = await web3.eth.getGasPrice();
    let gas = await web3.eth.estimateGas({
      to: contractAddress,
      value: 0,
      data: extraData,
      from: connectedAddress,
    });

    return { avgGasPrice, gas };
  } catch (e) {
    return { avgGasPrice: 0, gas: 0 };
  }
};

//////// uRvlt --------------------------------------------

// Function to stake rvltToken to rvltDAO.

export const stake = async (amount) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }

    const reqAmount = web3.utils.toWei(amount, "ether");
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const extraData = await uRvltContract.methods
      .deposit(0, reqAmount)
      .encodeABI();
    const gasData = await getGasFee(extraData, uRvltAddress, account[0]);

    const rvltStaked = await uRvltContract.methods.deposit(0, reqAmount).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });
    return rvltStaked;
  } catch (error) {
    return error;
  }
};

// Function to call withdraw amount function of rvltDAO

export const withdraw = async (amount) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const reqAmount = web3.utils.toWei(amount, "ether");

    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const extraData = await uRvltContract.methods
      .withdraw(0, reqAmount)
      .encodeABI();
    const gasData = await getGasFee(extraData, uRvltAddress, account[0]);

    const rvltWithdraw = await uRvltContract.methods
      .withdraw(0, reqAmount)
      .send({
        from: account[0],
        gas: gasData.gas,
        gasPrice: gasData.avgGasPrice,
      });
    return rvltWithdraw;
  } catch (error) {
    return error;
  }
};

// Function to call claim function of rvltDAO

export const claimUrvlt = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const extraData = await uRvltContract.methods.deposit(0, 0).encodeABI();
    const gasData = await getGasFee(extraData, uRvltAddress, account[0]);

    const rvltClaim = await uRvltContract.methods.deposit(0, 0).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });
    return rvltClaim;
  } catch (error) {
    return "";
  }
};

// Function to see rvltDAO balance

export const uRvltBalance = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const Balance = await uRvltContract.methods.balanceOf(account[0]).call();
    return web3.utils.fromWei(Balance, "ether");
  } catch (error) {
    return 0;
  }
};

export const pendingRvlt = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const poolL = await uRvltContract.methods.poolLength().call();
    let pRVLT1 = await uRvltContract.methods.pendingRVLT(0, account[0]).call();
    pRVLT1 = pRVLT1 > 1 ? pRVLT1 / Math.pow(10, 18) : pRVLT1;
    let pRVLT2 = 0;
    if (poolL > 1) {
      pRVLT2 = await uRvltContract.methods
        .pendingRVLT(parseFloat(poolL) - 1, account[0])
        .call();
      pRVLT2 = pRVLT2 > 1 ? pRVLT2 / Math.pow(10, 18) : pRVLT2;
    }
    const tpRVLT = parseFloat(pRVLT1) + parseFloat(pRVLT2);
    return tpRVLT;
  } catch (error) {
    return 0;
  }
};

export const cultManderRVLT = async (pid) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    let revolt = await uRvltContract.methods
      .pendingRVLT(pid, account[0])
      .call();
    return web3.utils.fromWei(revolt, "ether");
  } catch (e) {
    return 0;
  }
};

export const claimRvlt = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const extraData = await uRvltContract.methods.deposit(0, 0).encodeABI();
    const gasData = await getGasFee(extraData, uRvltAddress, account[0]);

    const cRVLT = await uRvltContract.methods.deposit(0, 0).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });
    return cRVLT;
  } catch (error) {
    return error;
  }
};

export const claimCultManderRVLT = async (pid) => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }

    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const extraData = await uRvltContract.methods.claimRVLT(pid).encodeABI();
    const gasData = await getGasFee(extraData, uRvltAddress, account[0]);

    const cRVLT = await uRvltContract.methods.claimRVLT(pid).send({
      from: account[0],
      gas: gasData.gas,
      gasPrice: gasData.avgGasPrice,
    });

    return cRVLT;
  } catch (error) {
    return error;
  }
};

export const highestStaker = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const checkHS = await uRvltContract.methods
      .checkHighestStaker(0, account[0])
      .call();
    return checkHS;
  } catch (error) {
    // console.log(error);
    return "";
  }
};

export const totalVotes = async () => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
  try {
    const votes = await uRvltContract.methods.getVotes(account[0]).call();
    return votes / Math.pow(10, 18);
  } catch (error) {
    return 0.0;
  }
};

export const delegate = async (address) => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);

  try {
    const delegated = await uRvltContract.methods
      .delegate(address)
      .send({ from: account[0] });
    return delegated;
  } catch (error) {
    if (error?.code === 4001) {
      return error;
    } else return { code: 4002 };
  }
};

export const totalStaked = async () => {
  const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);

  try {
    const staked = await uRvltContract.methods.totalSupply().call();
    return staked / Math.pow(10, 18);
  } catch (error) {
    // console.log(error);
    return 0.0;
  }
};

export const isCultMandator = async () => {
  try {
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const boolRes = await uRvltContract.methods
      .iCultMandator(account[0])
      .call();
    return boolRes;
  } catch (error) {
    // console.log(error);
    return 0.0;
  }
};

export const isCultMandatorWithAddress = async (address) => {
  try {
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const boolRes = await uRvltContract.methods.iCultMandator(address).call();
    return boolRes;
  } catch (error) {
    // console.log(error);
    return 0.0;
  }
};

export const totalRvltStaked = async () => {
  const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);

  try {
    const staked = await uRvltContract.methods.totalRVLTStaked().call();
    return staked / Math.pow(10, 18);
  } catch (error) {
    // console.log(error);
    return 0.0;
  }
};

export const Guardianship = async () => {
  const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);

  try {
    let highestStaker = 0;
    for (let i = 0; i < 50; i++) {
      highestStaker = await uRvltContract.methods
        .highestStakerInPool(0, i)
        .call();

      if (parseInt(highestStaker?.deposited)) {
        return (highestStaker?.deposited / Math.pow(10, 18)).toFixed(1);
      }
    }
    return 0;
  } catch (error) {
    // console.log(error);
    return 0.0;
  }
};

///////////// rvlt --------------------------------

// Function to send approved amount of rvltToken to rvltDAO

export const approve = async () => {
  try {
    const account = await getAccount();
    // console.log("account", account[0]);
    if (!account.length) {
      return 0;
    }
    const rvltTokenContract = new web3.eth.Contract(rvltABI, rvltAddress);
    const UINT256_MAX =
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
    let extraData = rvltTokenContract.methods
      .approve(uRvltAddress, UINT256_MAX)
      .encodeABI();
    const gasData = await getGasFee(extraData, rvltAddress, account[0]);

    const rvltApprove = await rvltTokenContract.methods
      .approve(uRvltAddress, UINT256_MAX)
      .send({
        from: account[0],
        gas: gasData.gas,
        gasPrice: gasData.avgGasPrice,
      });
    return rvltApprove;
  } catch (error) {
    // console.log("error", error);
    return error;
  }
};

// Function to call allowance function of rvltToken

export const RvltAllowance = async () => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const rvltTokenContract = new web3.eth.Contract(rvltABI, rvltAddress);
  try {
    const allowanceResponse = await rvltTokenContract.methods
      .allowance(account[0], uRvltAddress)
      .call();
    return allowanceResponse;
  } catch (error) {
    return 0.0;
  }
};

export const rvltBalance = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const rvltTokenContract = new web3.eth.Contract(rvltABI, rvltAddress);
    const rvltB = await rvltTokenContract.methods.balanceOf(account[0]).call();
    return web3.utils.fromWei(rvltB, "ether");
  } catch (error) {
    // console.log(error);
    return 0;
  }
};

export const totalBurned = async () => {
  try {
    const rvltTokenContract = new web3.eth.Contract(rvltABI, rvltAddress);
    const balance1 = await rvltTokenContract.methods
      .balanceOf(deadAddress1)
      .call();

    const balance = parseInt(balance1) / Math.pow(10, 18);
    return balance.toFixed(0);
  } catch (error) {
    // console.log(error);
    return "";
  }
};

export const cultBoughtandBurned = async () => {
  try {
    const rvltTokenContract = new web3.eth.Contract(rvltABI, rvltAddress);
    const treasuryTokenContract = new web3.eth.Contract(
      treasuryTokenABI,
      treasuryAddress
    );
    const wAddress = await treasuryTokenContract.methods
      .multSignWallet()
      .call();
    const balance1 = await rvltTokenContract.methods.balanceOf(wAddress).call();
    return balance1 / 10 ** 18;
  } catch (error) {
    return error;
  }
};

export const treasuryBalance = async () => {
  try {
    const rvltTokenContract = new web3.eth.Contract(rvltABI, rvltAddress);
    const balance1 = await rvltTokenContract.methods
      .balanceOf(deadAddress3)
      .call();

    const balance = parseInt(balance1) / Math.pow(10, 18);
    // console.log("tres", balance * 0.00002373);
    return balance.toFixed(2);
  } catch (error) {
    // console.log(error);
    return "";
  }
};

// Treasury Token function

export const revoltPriceInUsd = async (amount) => {
  try {
    const uRVLT = web3.utils.toWei(amount, "ether");
    const rvltTokenContract = new web3.eth.Contract(
      treasuryTokenABI,
      treasuryAddress
    );
    if (uRVLT === '0') {
      return 0
    }
    const uRVLTinUSD = await rvltTokenContract.methods
      .revoltPriceInUSD(uRVLT)
      .call();
    return parseFloat(uRVLTinUSD);
  } catch (error) {
    return "";
  }
};

export const poolLen = async () => {
  try {
    const uRvltContract = new web3.eth.Contract(uRvltABI, uRvltAddress);
    const poolL = await uRvltContract.methods.poolLength().call();
    return poolL;
  } catch (error) {
    // console.log(error);
    return 0.0;
  }
};



export const checkOptMember = async () => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltTokenContract = new web3.eth.Contract(OptABI, OptAddress)
    const response = await rvltTokenContract.methods
      .isOptedUser(account[0])
      .call()
    return response
  } catch (error) {
    console.log('error', error)
    return error
  }
}
export const submitOptin = async () => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltTokenContract = new web3.eth.Contract(OptABI, OptAddress)
    const response = await rvltTokenContract.methods
      .optIn()
      .send({ from: account[0] })
    return response
  } catch (error) {
    console.log('error', error)
    return error
  }
}
export const getPriceOptPool = async () => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltTokenContract = new web3.eth.Contract(OptABI, OptAddress)
    const response = await rvltTokenContract.methods.rvltPriceOptPool().call()
    console.log('response', response);
    return response
  } catch (error) {
    console.log('error', error)
    return error
  }
}
export const getUserBalance = async () => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltTokenContract = new web3.eth.Contract(uRvltABI, uRvltAddress)
    const response = await rvltTokenContract.methods
      .balanceOf(account[0])
      .call()
    console.log('response', response);
    const weiResponse = web3.utils.fromWei(response, 'ether')
    console.log('weiResponse', weiResponse);

    return weiResponse
  } catch (error) {
    console.log('error', error)
    return error
  }
}

export const userlockInfo = async () => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltTokenContract = new web3.eth.Contract(uRvltABI, uRvltAddress)
    const response = await rvltTokenContract.methods
      .userLockInfo(account[0])
      .call()
    return response
  } catch (error) {
    console.log('error', error)
    return error
  }
}

/** Migration flow starts */


/** Old RVLT Balance Method */

export const oldRvltBalance = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const RvltContract = new web3.eth.Contract(oldRVLTAbi, oldRVLTCompare);
    const Balance = await RvltContract.methods.balanceOf(account[0]).call();
    return web3.utils.fromWei(Balance, "ether");
  } catch (error) {
    return 0;
  }
};

/** Old RVLT Balance Method ends */


/** Old uRVLT Balance Method */
export const olduRvltBalance = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const uRvltContract = new web3.eth.Contract(olduRVLTAbi, olduRVLTCompare);
    const Balance = await uRvltContract.methods.balanceOf(account[0]).call();
    return web3.utils.fromWei(Balance, "ether");
  } catch (error) {
    return 0;
  }
};
/** Old RVLT Balance Method ends */


/** check allowance for RVLT*/

export const migrateRvltAllowanceCheck = async () => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const rvltTokenContract = new web3.eth.Contract(oldRVLTAbi, oldRVLTCompare);
  try {
    const allowanceResponse = await rvltTokenContract.methods
      .allowance(account[0], migrationAddress)
      .call();
    return allowanceResponse;
  } catch (error) {
    return 0.0;
  }
};

/** check allowance for RVLT ends */


/** check allowance for uRVLT*/
export const migrateuRvltAllowanceCheck = async () => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const rvltTokenContract = new web3.eth.Contract(olduRVLTAbi, olduRVLTCompare);
  try {
    const allowanceResponse = await rvltTokenContract.methods
      .allowance(account[0], migrationAddress)
      .call();
    return allowanceResponse;
  } catch (error) {
    return 0.0;
  }
};
/** check allowance for uRVLT ends */

/** Approve migrate contract in old RVLT */
export const approveOldRvltTokenForMigration = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const rvltTokenContract = new web3.eth.Contract(oldRVLTAbi, oldRVLTCompare);
    const UINT256_MAX = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
    let extraData = rvltTokenContract.methods.approve(migrationAddress, UINT256_MAX).encodeABI();
    const gasData = await getGasFee(extraData, oldRVLTCompare, account[0]);
    const rvltApprove = await rvltTokenContract.methods
      .approve(migrationAddress, UINT256_MAX)
      .send({
        from: account[0],
        gas: gasData.gas,
        gasPrice: gasData.avgGasPrice,
      });
    return rvltApprove;
  } catch (error) {
    return error;
  }
};

/** Approve migrate contract in old RVLT ends */

/** Approve migrate contract in old uRVLT */
export const approveOlduRvltTokenForMigration = async () => {
  try {
    const account = await getAccount();
    if (!account.length) {
      return 0;
    }
    const rvltTokenContract = new web3.eth.Contract(olduRVLTAbi, olduRVLTCompare);
    const UINT256_MAX = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
    let extraData = rvltTokenContract.methods.approve(migrationAddress, UINT256_MAX).encodeABI();
    const gasData = await getGasFee(extraData, olduRVLTCompare, account[0]);

    const rvltApprove = await rvltTokenContract.methods
      .approve(migrationAddress, UINT256_MAX)
      .send({
        from: account[0],
        gas: gasData.gas,
        gasPrice: gasData.avgGasPrice,
      });
    return rvltApprove;
  } catch (error) {
    return error;
  }
};

/** Approve migrate contract in old uRVLT ends */

/** Migrate RVLT tokens */
export const migrateRvlt = async (rvltAmount, rvltProof) => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltMigrateContract = new web3.eth.Contract(migrationABI, migrationAddress)
    const response = await rvltMigrateContract.methods.claimRVLT(rvltAmount, rvltProof).send({ from: account[0] });
    return response;
  } catch (error) {
    console.log('Migrate RVLT error', error)
    return error
  }
}
/** Migrate RVLT tokens */

/** Migrate uRVLT tokens */
export const migrateuRVLT = async (uRVLTAmount, uRVLTProof) => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltMigrateContract = new web3.eth.Contract(migrationABI, migrationAddress)
    const response = await rvltMigrateContract.methods.claimuRVLT(uRVLTAmount, uRVLTProof).send({ from: account[0] });
    return response;
  } catch (error) {
    console.log('error', error)
    return error
  }
}
/** Migrate uRVLT tokens ends */

/** Check if user already claimed rvlt or not  */
export const userRvltMigrateClaimStatus = async () => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltMigrateContract = new web3.eth.Contract(migrationABI, migrationAddress)
    const response = await rvltMigrateContract.methods.isUserClaimedRVLT(account[0]).call();
    return response;
  } catch (error) {
    return error
  }
}
/** Check if user already claimed rvlt or not ends  */

/** Check if user already claimed uRVLT or not  */
export const useruRvltMigrateClaimStatus = async () => {
  try {
    const account = await getAccount()
    if (!account.length) {
      return 0
    }
    const rvltMigrateContract = new web3.eth.Contract(migrationABI, migrationAddress)
    const response = await rvltMigrateContract.methods.isUserClaimeduRVLT(account[0]).call();
    return response;
  } catch (error) {
    return error
  }
}
/** Check if user already claimed uRVLT or not ends  */

/** Migration flow ends */