import React from 'react'
import BackButton from '../BackButton'

function BigBanner ({ children, to }) {
  return (
    <div className='w-full p-2'>
      <div className='bg-white w-full  py-5 px-5 sm:px-10 border-3 border-black rounded-3xl 2xl:-mt-4 3xl:-mt-12 m-auto sm:max-h-60 sm:min-h-65 max-h-125 min-h-115 flex justify-between mt-12 '>
        <div className='w-full items-center relative m-auto mt-0 sm:max-h-75 sm:min-h-75 max-h-125 min-h-115'>
          <div className=' top-14 w-full xl:max-h-65 xl:min-h-65  sm:max-h-60 sm:min-h-60 max-h-125 min-h-115 '>
            <div className=' w-full 3xl:max-h-65 3xl:min-h-65  sm:max-h-60 xl:max-h-65 xl:min-h-63 sm:min-h-60 flex flex-col items-center m-auto relative container-height'>
              {children}
            </div>
          </div>
          <BackButton to={to} />
        </div>
      </div>
    </div>
  )
}

export default BigBanner
