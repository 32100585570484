import React, { useEffect, useState } from "react";
import {
  stake,
  withdraw,
  rvltBalance,
  approve,
  RvltAllowance,
  uRvltBalance,
  pendingRvlt,
  claimRvlt,
  poolLen,
  cultManderRVLT,
  claimCultManderRVLT,
} from "../../Common/rvltFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectProposal } from "../../features/counter/proposalSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { correctNetworkId, isButtonDisabled } from "../../Common/config";

function StakingCard() {
  const { isMetamask, currentNetworkId } = useSelector(selectProposal);
  const [transactionInProgress, setProgress] = useState(false);
  const [loadingCultmanderReward, setLoadingCultmanderReward] = useState(false);
  const [cultManderRewardArray, setCultManderRewardArray] = useState([]);
  const [message, setMessage] = useState("");
  const [balance, setBalance] = useState({
    uRvlt: 0,
    rvlt: 0,
    claimableRvlt: "",
  });
  const [cAllowance, setAllowance] = useState(0);
  const [cultManderReward, setCultManderReward] = useState(false);

  const initialValues = {
    stake: "",
    withdraw: "",
  };
  const [values, setValues] = useState(initialValues);

  const notify = (Message) => toast(Message);
  const [currentPID, setcurrentPID] = useState("");

  const { dashboard } = useSelector(selectProposal);

  const { currentClaimCycle } = dashboard.cData;

  const changePID = (claimableRVLT) => {
    setcurrentPID(claimableRVLT);
  };

  const data = async () => {
    const Cbalance = await rvltBalance();
    const dCbalance = await uRvltBalance();
    const rvltAllowance = await RvltAllowance();
    const rvltClaimable = await pendingRvlt();

    if (!isMetamask) {
      notify("Metamask not found, please install metamask to continue");
    }
    setAllowance(rvltAllowance);
    setBalance({
      uRvlt: dCbalance,
      rvlt: Cbalance,
      claimableRvlt: rvltClaimable,
    });
  };
  useEffect(() => {
    data();
  }, []);

  const cultManderRewardData = async () => {
    setLoadingCultmanderReward(true);

    const cultManderArray = [];
    const poolL = await poolLen();
    for (let i = 1; i < poolL - 1; i++) {
      const rvlt = await cultManderRVLT(i);
      if (parseInt(rvlt)) {
        cultManderArray.push({ pid: i, rvlt });
      }
    }
    setcurrentPID(cultManderArray[0]?.pid);
    setCultManderRewardArray(cultManderArray);
    setLoadingCultmanderReward(false);
  };
  useEffect(() => {
    cultManderRewardData();
  }, []);

  useEffect(() => {
    if (currentNetworkId !== correctNetworkId) {
      notify("Wrong Network");
    }
  }, [currentNetworkId]);

  const handleInputChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const approveOrStake = async () => {
    if (cAllowance > 0) {
      setProgress(true);
      setMessage("Depositing RVLT");
      if (!parseFloat(values.stake)) {
        return 0;
      }
      const stakeC = await stake(values.stake);

      if (stakeC?.code === 4001 || !values.stake) {
        notify("Transaction Rejected");
      } else {
        notify("Transaction Confirmed");
      }
      setProgress(false);
    } else {
      setProgress(true);
      setMessage("Approving RVLT");
      const approveCT = await approve();
      const rvltAllowance = await RvltAllowance();
      if (approveCT?.code === 4001) {
        notify("Transaction Rejected");
      } else {
        const message = !approveCT
          ? "Please verify that you approve transaction from metamask."
          : "Transaction Confirmed";
        notify(message);
      }
      setAllowance(rvltAllowance);
      setProgress(false);
    }
    data();
  };

  const withdrawRvlt = async () => {
    setProgress(true);
    setMessage("Withdrawing RVLT");
    const withdrawC = await withdraw(values.withdraw);
    if (withdrawC?.code === 4001 || !values.withdraw) {
      notify("Transaction Rejected");
    } else {
      notify("Transaction Confirmed");
    }
    setProgress(false);
    data();
  };
  const ClaimRvlt = async () => {
    let claim;
    setProgress(true);
    setMessage("Claiming Rewards");
    if (cultManderReward) {
      claim = await claimCultManderRVLT(currentPID);
    } else {
      claim = await claimRvlt();
    }
    if (claim?.code === 4001) {
      notify("Transaction Rejected");
    } else {
      notify("Transaction Confirmed");
      if (cultManderReward) {
        cultManderRewardData();
      } else {
        window.location.reload();
      }
    }
    setProgress(false);
    data();
  };

  return (
    <>
      {cultManderReward ? (
        <div className="flex flex-col w-full items-center font-Nixie">
          {currentNetworkId !== correctNetworkId ? (
            <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 text-xl pr-3 1xl:text-2xl">
              Wrong Network ...
            </p>
          ) : loadingCultmanderReward && !cultManderRewardArray.length ? (
            <div className="flex items-center space-x-0">
              <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 text-xl pr-3 1xl:text-2xl">
                Loading ...
              </p>
              <span>
                <AiOutlineLoading3Quarters className="3xl:w-10 3xl:h-10 3xl:mt-5 animate-spin w-6 h-6" />
              </span>
            </div>
          ) : cultManderRewardArray.length ? (
            <div className="flex flex-col w-9/12">
              <div className="1xl:h-48 h-28 w-full bg-gray-300 border-2 rounded-md text-center overflow-y-scroll mt-8">
                {cultManderRewardArray?.map((reward) => (
                  <p className="sm:mt-2 sm:ml-3 1xl:text-lg text-sm sm:text-base ">
                    Cycle : {reward.pid}{" "}
                    <span className="ml-3">
                      RVLT :{" "}
                      {parseFloat(reward.rvlt).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                ))}
              </div>
              <select
                className=" 3xl:h-14 3xl:w-64 3xl:text-4xl  3xl:mt-3 3xl:mb-0 2xl:h-12 2xl:w-full 2xl:text-3xl 2xl:mt-3 border mb-3 mt-6 w-full lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
                onChange={(event) => changePID(event.target.value)}
                value={currentPID}
              >
                {cultManderRewardArray?.map((reward) => (
                  <option key={reward.pid} value={reward.pid}>
                    Cycle {reward.pid} RVLT
                  </option>
                ))}
              </select>
              <button
                className="3xl:h-14 3xl:w-64 3xl:text-4xl  3xl:mt-3 3xl:mb-0 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border mb-3 mt-9 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
                onClick={() => ClaimRvlt()}
                disabled={
                  transactionInProgress ||
                  currentNetworkId !== correctNetworkId ||
                  isButtonDisabled
                }
              >
                Claim RVLT
              </button>
            </div>
          ) : (
            <p className="1xl:text-2xl sm:text-xl text-sm">
              No CULTMander reward found
            </p>
          )}

          <button
            className="absolute bottom-0 1xl:text-2xl sm:text-lg text-sm font-bold underline "
            onClick={() => setCultManderReward(!cultManderReward)}
          >
            Back to Stake
          </button>
          {transactionInProgress ? (
            <div className="flex items-center space-x-0">
              <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 text-lg pr-3">
                {message}
              </p>
              <span>
                <AiOutlineLoading3Quarters className="3xl:w-10 3xl:h-10 3xl:mt-5 animate-spin w-6 h-6" />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="flex flex-col w-full items-center h-full justify-evenly">
          <p className="font-Nixie font-semibold lg:text-2xl text-lg 2xl:mt-12 2xl:text-4xl 3xl:text-5xl 1xl:mt-6">
            Stake RVLT
          </p>
          <div className="flex flex-col  w-9/12">
            <div className="flex justify-between w-full m-auto">
              <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
                Balance:
              </p>
              <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
                {balance?.rvlt
                  ? (parseInt(balance?.rvlt * 1000) / 1000).toLocaleString()
                  : 0}
              </p>
            </div>
            <div className="relative">
              <input
                // style={{ textAlign: "right", paddingRight: "70px" }}
                className="3xl:pr-36 3xl:h-14 3xl:mt-3 2xl:h-12 2xl:mt-2 2xl:pr-28 pr-18 text-right w-full mx-auto 1xl:mb-4 mb-2 lg:h-9 h-7 border rounded-lg font-Nixie border-gray-600 font-bold 3xl:text-4xl 2xl:text-3xl lg:text-lg text-base "
                type="number"
                name="stake"
                value={values.stake}
                onChange={handleInputChange}
                disabled={cAllowance < 1}
              />
              {cAllowance > 0 ? (
                <button
                  className="3xl:text-4xl 3xl:mt-3 3xl:h-14 3xl:w-32 2xl:text-3xl 2xl:h-12 2xl:mt-2 2xl:w-24 absolute right-0 border-l w-16 lg:h-9 h-7 m-auto  lg:text-xl text-lg font-Nixie border-gray-600 font-medium"
                  onClick={() => setValues({ ...values, stake: balance?.rvlt })}
                >
                  Max
                </button>
              ) : (
                ""
              )}
            </div>
            <button
              className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
              onClick={() =>
                balance?.rvlt < 1 &&
                cAllowance > 0 &&
                values.stake > balance?.rvlt
                  ? notify("You don't have RVLT token to stake")
                  : approveOrStake()
              }
              disabled={
                transactionInProgress ||
                currentNetworkId !== correctNetworkId ||
                isButtonDisabled
              }
            >
              {cAllowance > 0 ? "Stake" : "Approve"}
            </button>
          </div>
          <div className="flex flex-col  mt-0 w-9/12 2xl:mt-6">
            <div className="flex justify-between w-full m-auto">
              <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
                uRVLT Balance:
              </p>
              <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
                {balance?.uRvlt
                  ? (parseInt(balance?.uRvlt * 1000) / 1000).toLocaleString()
                  : 0}
              </p>
            </div>
            <div className="relative">
              <input
                // style={{ textAlign: "right", paddingRight: "70px" }}
                className="3xl:pr-36 3xl:h-14 3xl:mt-3 2xl:h-12 2xl:mt-2 2xl:pr-28 pr-18 text-right w-full mx-auto 1xl:mb-4 mb-2 lg:h-9 h-7 border rounded-lg font-Nixie border-gray-600 font-bold 3xl:text-4xl 2xl:text-3xl lg:text-lg text-base "
                type="number"
                name="withdraw"
                value={values.withdraw}
                onChange={handleInputChange}
              />
              <button
                className="3xl:text-4xl 3xl:mt-3 3xl:h-14 3xl:w-32 2xl:text-3xl 2xl:h-12 2xl:mt-2 2xl:w-24 absolute right-0 border-l w-16 lg:h-9 h-7 m-auto lg:text-xl text-lg font-Nixie border-gray-600 font-medium"
                onClick={() =>
                  setValues({ ...values, withdraw: balance?.uRvlt })
                }
              >
                Max
              </button>
            </div>
            <button
              className="3xl:h-14 3xl:w-64 3xl:text-4xl 3xl:mt-3 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border 1xl:mb-4 mb-2 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
              onClick={() =>
                balance?.uRvlt < 1 && values.withdraw > balance?.uRvlt
                  ? notify("You don't have uRVLT token to withdraw")
                  : withdrawRvlt()
              }
              disabled={
                transactionInProgress ||
                currentNetworkId !== correctNetworkId ||
                !parseFloat(values.withdraw) ||
                isButtonDisabled
              }
            >
              Withdraw
            </button>
          </div>
          <div className="flex flex-col  mt-0 w-9/12 2xl:mt-6">
            <div className="flex justify-between w-full m-auto">
              <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
                Cycle {currentClaimCycle} RVLT:
              </p>
              <p className="3xl:text-4xl 2xl:text-3xl lg:text-lg text-base font-extralight font-Nixie">
                {balance?.claimableRvlt
                  ? balance?.claimableRvlt.toLocaleString()
                  : balance?.claimableRvlt === 0
                  ? 0
                  : ""}
              </p>
            </div>
            <button
              className="3xl:h-14 3xl:w-64 3xl:text-4xl  3xl:mt-3 3xl:mb-0 2xl:h-12 2xl:w-56 2xl:text-3xl 2xl:mt-3 border 1xl:mt-3 mb-1 w-32 lg:h-9 h-7 m-auto rounded-lg lg:text-xl text-lg font-Nixie bg-buttonBg border-gray-600 font-medium"
              onClick={() =>
                balance?.claimableRvlt <= 0
                  ? notify("You have no RVLT to claim.")
                  : ClaimRvlt()
              }
              disabled={
                transactionInProgress ||
                currentNetworkId !== correctNetworkId ||
                isButtonDisabled
              }
            >
              Claim RVLT
            </button>
            <ToastContainer
              autoClose={5000}
              hideProgressBar={true}
              // toastClassName={css({ fontFamily: "Times New Roman, Serif" })}
            />
          </div>
          <button
            className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 1xl:text-xl underline sm:text-base text-xs  sm:mt-2 mt-0 font-Nixie"
            onClick={() => setCultManderReward(!cultManderReward)}
          >
            Claim rewards for previous cycles
          </button>
          {currentNetworkId !== correctNetworkId ? (
            <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 sm:text-xl text-base">
              Wrong Network...
            </p>
          ) : (
            ""
          )}

          {transactionInProgress ? (
            <div className="flex items-center space-x-0">
              <p className="3xl:text-4xl 3xl:-mb-24 2xl:text-3xl 3xl:h-28 text-lg pr-3">
                {message}
              </p>
              <span>
                <AiOutlineLoading3Quarters className="3xl:w-10 3xl:h-10 3xl:mt-5 animate-spin w-6 h-6" />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default StakingCard;
