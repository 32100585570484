import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  proposalLoading,
  selectProposal,
  setCustomRoute
} from '../../features/counter/proposalSlice'
import CompleteSort from '../Sorting/CompleteSort'
import BigBanner from '../Covers/BigBanner'
import ClosedProposalItems from './ClosedProposalItems'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { correctNetworkId } from '../../Common/config'
import {
  bubbleSort,
  fetchSortSession,
  updateSortSession
} from '../../Common/Utils'

function ClosedProposal () {
  const { proposals, loading, currentNetworkId } = useSelector(selectProposal)
  const [pendingData, setPendingData] = useState()
  const dispatch = useDispatch()

  const [showDropDown, setShowDropDown] = useState(false)
  const [activeStatus, setActiveStatus] = useState({
    id: false,
    voteLow: false,
    voteHigh: false
  })
  const [idSort, setIdSort] = useState('ascending')

  const handleSort = async (
    sortParameter,
    selectParameter,
    sortingMode = 'descending'
  ) => {
    dispatch(proposalLoading(true))
    const sort = await bubbleSort(
      pendingData,
      pendingData?.length,
      sortParameter,
      sortingMode
    )
    setPendingData(sort)
    dispatch(proposalLoading(false))
    updateSortSession(sortParameter, selectParameter, sortingMode)
    let statusObj = {}
    Object.getOwnPropertyNames(activeStatus).map(data => {
      selectParameter === data
        ? (statusObj[data] = true)
        : (statusObj[data] = false)
    })
    setActiveStatus(statusObj)
  }

  const handleDropDown = () => {
    setShowDropDown(!showDropDown)
  }

  const updateProposal = async () => {
    dispatch(setCustomRoute('closedProposal'))
    const tempPendingData = proposals?.length
      ? proposals.filter(
          details =>
            details?.stateName === 'Canceled' ||
            details?.stateName === 'Defeated' ||
            details?.stateName === 'Expired' ||
            details?.stateName === 'Executed'
        )
      : []

    const session = await fetchSortSession()
    if (!session) {
      const lastFirst = tempPendingData?.reverse()
      setPendingData(lastFirst)
      return
    }
    const sortedArr = await bubbleSort(
      tempPendingData,
      tempPendingData.length,
      session.sortParameter,
      session.sortingMode
    )
    setPendingData(sortedArr)
    // setPendingData(tempPendingData)
  }
  useEffect(() => {
    updateProposal()
  }, [proposals])

  return (
    <BigBanner to='proposalOptions'>
      {/* <p className="4xl:text-6xl 3xl:text-5xl 3xl:mb-10 3xl:mt-8 2xl:text-4xl xl:text-3xl 2xl:mb-8 2xl:mt-6 text-2xl font-semibold font-Nixie mb-4">
        Completed Acts
      </p> */}
      <div className='w-full flex justify-between'>
        <div
          className='relative flex justify-between px-3 items-center w-button border border-black rounded-md'
          onClick={handleDropDown}
        >
          <span className='text-md md:text-lg'>Sort</span>{' '}
          <span className='relative text-2xl -top-1'> &#x2304;</span>
        </div>
        {showDropDown && (
          <CompleteSort
            handleSort={handleSort}
            activeStatus={activeStatus}
            handleDropDown={handleDropDown}
            setIdSort={setIdSort}
            idSort={idSort}
          />
        )}
        <p className='4xl:text-6xl 3xl:text-5xl 3xl:mb-10 3xl:mt-8 2xl:text-4xl xl:text-3xl 2xl:mb-8 2xl:mt-6  text-xl sm:text-2xl font-semibold font-Nixie mb-4'>
          Completed Acts
        </p>
        <div></div>
      </div>

      <div className='overflow-y-scroll font-Nixie font-medium sm:text-lg text-sm min-w-full 2xl:max-h-55 max-h-50'>
        {!loading && pendingData ? (
          pendingData?.length ? (
            pendingData?.map(details => (
              <div key={details.id}>
                <ClosedProposalItems details={details} />
              </div>
            ))
          ) : (
            <div className='flex sm:w-full justify-evenly items-center'>
              {currentNetworkId === correctNetworkId ? (
                <p className='text-xl sm:text-2xl m-auto font-semibold 3xl:text-4xl '>
                  No completed acts found
                </p>
              ) : (
                <p className='text-2xl m-auto font-semibold 3xl:text-4xl'>
                  Wrong Network
                </p>
              )}
            </div>
          )
        ) : (
          <div className='flex sm:w-5/12 m-auto w-full justify-evenly items-center'>
            <p className='3xl:text-6xl  3xl:mt-10 sm:text-2xl text-xl'>
              Loading ...
            </p>
            <AiOutlineLoading3Quarters className='3xl:mt-10 3xl:w-16 3xl:h-16 animate-spin sm:w-8 sm:h-8 h-6 w-6' />
          </div>
        )}
      </div>
      <Link
        to='/proposalOptions'
        className='text-lg underline font-Nixie mt-3 absolute -bottom-3 sm:invisible visible'
      >
        Back
      </Link>
    </BigBanner>
  )
}

export default ClosedProposal
