import React from 'react'

function ShortPaperCover ({ children, isRotate }) {
  return (
    <div className=' 3xl:max-h-60 lg:min-h-72 lg:max-h-72 h-96 items-center relative m-auto mt-0 2xl:mt-16 lg:-top-10'>
      <img
        className={`4xl:min-w-680 3xl:min-w-620 w-full max-w-lg  transform ${
          isRotate
            ? '-rotate-90 lg:max-h-60 lg:min-h-60  xsm:h-96 h-86 2xl:max-h-65 2xl:min-h-65'
            : 'lg:max-h-68 lg:min-h-68  h-96 2xl:max-h-65 2xl:min-h-65'
        }`}
        src='images/card.png'
        alt='not found'
      />
      <div
        className={` 3xl:mt-3 3xl:h-full 3xl:max-h-55  absolute  left-0 lg:pt-3 pt-0 justify-evenly flex flex-col m-auto  w-full items-center  ${
          isRotate
            ? 'h-3/5 sm:top-12 top-16 2xl:mt-14'
            : 'h-4/5 4xl:min-w-680 3xl:min-w-620 max-w-lg top-5'
        } `}
      >
        {children}
      </div>
    </div>
  )
}

export default ShortPaperCover
