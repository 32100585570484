import React from "react";

function SocialLInks() {
  return (
    <div className=" sm:w-2/5 lg:w-1/5 w-full m-auto flex justify-evenly ">
      <a
        href="https://twitter.com/wearecultdao"
        target="_blank"
        className="hover:animate-bounce"
        rel="noreferrer"
      >
        <img
          className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
          src="images/twitter.png"
          alt="not found"
        />
      </a>

      <a
        href="https://discord.com/invite/K6VCDYphSA"
        target="_blank"
        className="hover:animate-bounce"
        rel="noreferrer"
      >
        <img
          className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14 "
          src="images/Discord.png"
          alt="not found"
        />
      </a>
      <a
        href="https://t.me/revolt2earn"
        target="_blank"
        className="hover:animate-bounce"
        rel="noreferrer"
      >
        <img
          className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
          src="images/telegram.png"
          alt="not found"
        />
      </a>
      <a
        href="http://doc.cultdao.io/"
        target="_blank"
        className="hover:animate-bounce"
        rel="noreferrer"
      >
        <img
          className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
          src="images/book.png"
          alt="not found"
        />
      </a>
      <a
        href="https://wearecultdao.medium.com/emancipate-the-many-from-wage-slavery-rvlt-b43e6431d51e"
        target="_blank"
        className="hover:animate-bounce"
        rel="noreferrer"
      >
        <img
          className=" w-9 h-9 3xl:w-20 2xl:w-14 3xl:h-20 2xl:h-14"
          src="images/Medium.png"
          alt="not found"
        />
      </a>
    </div>
  );
}

export default SocialLInks;
