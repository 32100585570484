import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { correctNetworkId } from "../../Common/config";
import { currentProposal } from "../../features/counter/proposalSlice";
import CompleteProposalModal from "../Modals/CompletedProposalModal";
var Web3 = require("web3");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

function ClosedProposalItems({ details }) {
  const dispatch = useDispatch();
  const [endIn, setEndIn] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setEndInOrStatus(details);
  }, [details]);

  const approvePercentage = (data) => {
    const favour = parseFloat(data?.forVotes);
    const against = parseFloat(data?.againstVotes);
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }

    return ((favour / total) * 100).toFixed(2);
  };

  const rejectPercentage = (data) => {
    const favour = parseFloat(data?.forVotes);
    const against = parseFloat(data?.againstVotes);
    const total = favour + against;
    if (favour === 0 && against === 0) {
      return 0;
    }
    return ((against / total) * 100).toFixed(2);
  };

  const setEndInOrStatus = async (data) => {
    // const value = await web3.eth.getBlock(parseInt(data?.endBlock));
    try {
      var date = new Date(data?.endTime * 1000);
      setEndIn(date.toLocaleString("en-GB", { hour12: false }));
    } catch (e) {
      setEndIn("0");
    }
  };
  return (
    <div
      /* className="flex flex-col 3xl:text-4xl 2xl:text-3xl xl:text-2xl" */
      className="flex flex-col 3xl:text-4xl 2xl:text-3xl xl:text-2xl hover:bg-gray-100 pl-2 rounded-xl py-2"
      onClick={() => {
        dispatch(currentProposal(details));
      }}
      id={details?.id}
    >
      {/* <Link to="/proposalDetail">
        <span className="underline underline-offset-1 mr-1 3xl:text-4xl 2xl:text-3xl xl:text-2xl 2xl:mt-3">
          Act of Revolt({details?.id}):
        </span>
        ({details?.description?.proposalName})
      </Link> */}

      <div
        onClick={() => {
          setShowModal(true);
        }}
        className="cursor-pointer"
      >
        <span className="underline underline-offset-1 mr-1 3xl:text-4xl 2xl:text-3xl xl:text-2xl 2xl:mt-3">
          Act of Revolt({details?.id}):
        </span>
        ({details?.description?.proposalName})
      </div>

      <div className=" flex flex-col mt-2">
        <div className="flex flex-col sm:flex-row sm:items-center items-start">
          <div className="3xl:w-96 2xl:mt-3 2xl:w-80 xl:w-60 sm:w-44 w-8/12 flex flex-col mr-4 border rounded border-slate-600 ">
            <div className="flex justify-between px-1 border-b border-slate-600 relative font-semibold">
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl  3xl:h-14 2xl:pt-1">
                Approve
              </p>{" "}
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                {approvePercentage(details)}%
              </p>
              <div
                style={{ width: `${approvePercentage(details)}%` }}
                className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
              ></div>
            </div>
            <div className="flex  justify-between px-1 relative font-semibold">
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-1">
                Reject
              </p>{" "}
              <p className="3xl:text-4xl 2xl:text-3xl xl:text-2xl 3xl:h-14 2xl:pt-2">
                {rejectPercentage(details)}%
              </p>
              <div
                style={{ width: `${rejectPercentage(details)}%` }}
                className="absolute top-0 left-0 opacity-40 bg-gray-500 min-h-full"
              ></div>
            </div>
          </div>
          <div>
            <p>
              Result:{" "}
              <span className="font-semibold">{details?.stateName}</span>
            </p>
            <div>
              {details?.stateName === "Canceled" ? (
                <br></br>
              ) : (
                <p>
                  Ended:{" "}
                  <span className="font-semibold">
                    {" "}
                    {localStorage.getItem("chainId") !==
                    correctNetworkId.toString()
                      ? "N/A"
                      : endIn}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="3xl:mt-6 3xl:mb-6 2xl:mt-3 2xl:mb-3 flex flex-col sm:flex-row sm:items-center items-start justify-between px-1 border-b border-slate-600 relative font-semibold mt-2 ">
          <div className="flex flex-col">
            <div className="flex text-sm">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">Approved :</p>
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.forVotes).toLocaleString()}
              </p>
            </div>
            <div className="flex text-sm 2xl:mt-3 ">
              <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">Rejected :</p>{" "}
              <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
                {(details?.againstVotes).toLocaleString()}
              </p>
            </div>
          </div>
          <div className="flex  text-sm">
            <p className="3xl:text-3xl 2xl:text-2xl xl:text-xl">
              Total Votes :{" "}
            </p>
            <p className="ml-1 3xl:text-3xl 2xl:text-2xl xl:text-xl">
              {(
                parseFloat(details?.againstVotes) +
                parseFloat(details?.forVotes)
              ).toLocaleString()}
            </p>
          </div>
        </div>
      </div>
      <CompleteProposalModal
        showModal={showModal}
        details={details}
        setShowModal={setShowModal}
      />
    </div>
  );
}

export default ClosedProposalItems;
